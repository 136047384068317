<template>
  <div class="d-flex" style="position: relative">
    <v-text-field
      outlined
      :value11="value"
      v-model="money"
      :label="model.title"
      :error-messages="error"
      :disabled="model.disabled || false"
      :hide-details="config.hideDetails || false"
      :dense="config.dense || false"
      :readonly="config.readonly || false"
      type="number"
      @input23="$emit('input', $event)"
      @keypress="filter($event)"
      @focus="indicatorChange = true"
      @blur="indicatorChange = false"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    model: Object,
    config: { type: Object, default: {} },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    values: Object,
  },
  data: () => {
    return {
      indicatorChange: false, // индикатор редактирования поля
    };
  },
  computed: {
    money: {
      get() {
        return this.indicatorChange ? this.value : Number(this.value).toLocaleString();
      },
      set(v) {
        let value = +v.replace(/\s/g, "");
        this.$emit("input", value);
      },
    },
    isDisable() {
      return this.model?.disabled || this.disabled;
    },

  },
  methods: {
    filter: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>
