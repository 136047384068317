<template>
  <v-navigation-drawer
    color="rgb(89, 89, 89)"
    v-model="$root.leftMenuDrawer"
    :right="$vuetify.rtl"
    mobile-break-point="960"
    dark
    app
    width="260"
    v-bind="$attrs"
  >
    <v-divider class="mb-1" />
    <v-list>
      <v-list-item class="mb-0 justify-space-between pl-3">
        <v-img :src="require('@/assets/logo.png')" />
      </v-list-item>
    </v-list> 

    <v-divider></v-divider>

    <list-main-menu :value="mainMenu" :role="$root.profile.role" />
    <template v-slot:append>
      <v-list-item link @click="$root.logout">
        <v-list-item-icon>
          <i class="fas fa-sign-out-alt"></i>
        </v-list-item-icon>
        <v-list-item-title>Выйти</v-list-item-title>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      mainMenu: this.$root.config.mainMenu,
    };
  },
  created() {},
  methods: {
    getImage() {
      let im = this.$store.getters["auth/profile"].image;
      let path;

      if (im) {
        path =
          this.$root.config.baseUrl +
          "/image/download/" +
          im +
          "?width=50&height=50";
      } else {
        path = "/img/man.png";
      }
      return path;
    },
  },
};
</script>