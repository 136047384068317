<template>
  <div class="py-1 mb-4">
    <v-card :class="{ 'error--text': showError }">
      <v-card-title class="py-2">
        {{ model.title }}
        <v-spacer></v-spacer>

        <v-btn class="ma-1" @click="add()" color="primary">+ {{ model.title }}</v-btn>
      </v-card-title>

      <v-card-text>
        <div v-if="loaded">
          <v-row v-if="true" style="min-height: 150px; max-height1: 550px; overflow: auto" @dragover1="(e) => e.preventDefault()">
            <v-expansion-panels tile>
              <v-expansion-panel
                v-for="(el, key) in items"
                :key="key"
                width="100%"
                draggable1="true"
                @drop1="dropItem(el, key, e)"
                @dragover1="(e) => e.preventDefault()"
                @dragove1r="(e) => onDragOver(el, key, e)"
                @dragend1="(e) => finishDrag(el, key, e)"
                @dragstart1="(e) => startDrag(el, key, e)"
                @dragleave1="onDragLeave(el, key, $event)"
                :class="{ over: el === over.item && el !== dragFrom, [over.dir]: el === over.item && el !== dragFrom }"
              >
                <v-expansion-panel-header hide-actions>#{{ el }} - {{ getInfoItem(el).name }} </v-expansion-panel-header>
                <v-expansion-panel-content style="background-color: #fff3e0" class="pt-2">
                  <div style="text-align: initial; border: 1px" v-html="getInfoItem(el).content"></div>
                </v-expansion-panel-content>
                <v-btn
                  v-if="!readonly"
                  class="mx-0"
                  fab
                  dark
                  x-small
                  color="error"
                  style="position: absolute; right: 8px; top: 8px"
                  title="Удалить"
                  @click.prevent="remove(key)"
                >
                  <v-icon dark> mdi-close </v-icon>
                </v-btn>
                <v-btn
                  v-if="!readonly && key"
                  class="mx-0"
                  fab
                  dark
                  x-small
                  color="error"
                  style="position: absolute; right: 48px; top: 8px"
                  title="Удалить"
                  @click.prevent="moveItem(key, el, 1)"
                >
                  <v-icon dark> mdi-arrow-up-bold </v-icon>
                </v-btn>
                <v-btn
                  v-if="!readonly && key + 1 < items.length"
                  class="mx-0"
                  fab
                  dark
                  x-small
                  color="error"
                  style="position: absolute; top: 8px"
                  :style="key ? 'right: 88px;' : 'right: 48px;'"
                  title="Удалить"
                  @click.prevent="moveItem(key, el, 0)"
                >
                  <v-icon dark> mdi-arrow-down-bold </v-icon>
                </v-btn>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <div class="v-text-field__details" v-if="showError">
      <div class="v-messages theme--light" role="alert">
        <div class="v-messages__wrapper error--text">{{ err }}</div>
      </div>
    </div>
    <v-dialog v-model="dialogAdd" hide-overlay persistent max-width="1200">
      <v-card class="">
        <v-card-title>Выбор элемента</v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="true" class="pt-8" style="height: 70vh; overflow: auto">
          <a-table-f-data2
            ref="table"
            :dataTable="infoItems.filter((ii) => !items.includes(ii.id))"
            :model="$store.getters['config/get'].models.info.list"
            :searchable="true"
            :useQuery="false"
            :defaults="{ sort: { key: 'id', order: 'DESC' } }"
            @click="(e) => addItem(e.row)"
          />
        </v-card-text>
        <v-card-text v-else class="pt-8">
          <div no-gutters class="mb-2 d-flex flex-wrap align-content-start">
            <div v-for="(info, i) in infoItems.filter((ii) => !items.includes(ii.id))" :key="i" cols="0" class="mx-2">
              <v-card class="ma-1" @click="addItem(info)">
                <v-card-subtitle>#{{ info.id }} {{ info.name }} </v-card-subtitle>
              </v-card>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogAdd = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Container, Draggable } from "vue-dndrop";
export default {
  components: { Container, Draggable },
  props: {
    value: Array,
    model: Object,
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      loaded: false,
      questions: [],
      infoItems: [],
      dialogAdd: false,
      showError: false,
      err: null,
      over: {},
      startLoc: 0,
      dragging: false,
      dragFrom: {},
    };
  },
  created() {
    this.getInfoItems();
  },
  watch: {
    error(e) {
      this.err = e;
      if (e) {
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
          this.err = "";
        }, 2000);
      }
    },
  },
  computed: {
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    items: {
      get() {
        let d;
        try {
          // d = JSON.parse(this.value);
          d = this.value;
          if (!d) d = [];
        } catch (error) {
          d = [];
        }
        return d;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    dropItem: function (e) {
      console.log("dropItem", e);
      return;
      if (this.dragFrom == this.model.name) return;
      let el = this.items;
      if (!Array.isArray(el)) el = [];
      //   el.unshift(e.dataTransfer.getData("text"));
      el.splice(this.over?.pos || 0, 0, e.dataTransfer.getData("text"));
      this.items = el;
    },
    getInfoItem(id) {
      return this.infoItems.find((i) => i.id == id) || {};
    },
    getQuestionById(id) {
      let res =
        this.questions.find((q) => {
          return q.q_id == id;
        }) || {};
      return res;
    },
    async getInfoItems() {
      let res = [];
      let info = [];
      let params = {
        sort: JSON.stringify({ key: "id", order: "asc" }),
        pager: { limit: 0 },
      };
      let resp = await this.$axios.get("/mko/information", { params });
      if (resp.data.status == "ok") {
        info = resp.data.data;
      }

      this.infoItems = [...info];

      this.loaded = !false;
    },

    startDrag(item, i, e) {
      console.log("startDrag", item);
      e.dataTransfer.setData("text", item);
      e.dataTransfer.setData("from", JSON.stringify(this.model));
      e.dataTransfer.effectAllowed = "move";
      // this.startLoc = e.clientY;
      this.dragging = true;
      this.dragFrom = this.model.name;
    },

    finishDrag(item, pos) {
      console.log("finishDrag", item, pos);
      if (this.readonly) return;
      let el = this.items;
      el.splice(pos, 1);
      el.splice(this.over.pos, 0, item);
      this.items = el;
      setTimeout(() => {
        this.over = {};
      }, 50);
    },

    onDragOver(item, pos, e) {
      console.log("onDragOver", item, pos);
      if (this.over.pos == pos) return;
      if (this.dragFrom != this.model.name) return;
      if (this.readonly) return;
      e.preventDefault();
      let dir;
      this.over = { item, pos, dir };
    },
    onDragLeave(item, pos, e) {
      console.log("onDragLeave", item, pos);
      if (this.over.pos == pos) this.over = {};
    },
    remove(key) {
      let e = this.items;
      e.splice(key, 1);
      this.items = e;
    },
    add() {
      this.dialogAdd = true;
    },

    addItem(el) {
      console.log(el);
      let items = this.items;
      items = [...items, el.id];
      this.items = items;
    },
    moveItem(key, item, pr) {
      console.log(key, pr);
      let e = this.items;
      e.splice(key, 1);
      console.log(key, pr);
      e.splice(key + (pr ? -1 : +1), 0, item);
      //  if (!pr) e.splice(key + 1, 1);

      this.items = e;
    },
  },
};
</script>
<style lang="scss">
.over {
  opacity: 0.6;
}
</style>
