/** @format */

const status = [
  { value: 0, text: 'Новый' },
  { value: 1, text: 'Активный' },
  { value: 10, text: 'Не активный' },
];
const deliveryMan = {
  title: 'Экспедиторы',
  routeName: 'deliveryMan',
  accessName: 'deliveryMan',
  api: '/users/deliveryman',

  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'name', title: 'Имя', type: 'string', sortable: true, width: 200 },
    { name: 'login', title: 'Телефон', type: 'string', sortable: true, width: 200 },
    { name: 'organization_name', title: 'Организация', type: 'string', sortable: true, width: 200 },
    { name: 'status', title: 'Статус', type: 'select', options: status, sortable: true, width: 200 },
  ],
  form: [
    { name: 'name', title: 'Имя', type: 'string', validator: ['req'], return: 'string' },
    { name: 'date_birth', title: 'Дата рожденияя', type: 'datestring', validator: ['req'] },
    { name: 'login', title: 'Телефон', type: 'phone', validator: ['req', 'tel', 'login'], mask: '996*********' },
    { name: 'status', title: 'Статус', validator: ['req'], default: 1 },
    { name: 'role', validator: ['req'], default: 50 },
    { name: 'images', title: 'Фото', type: 'images', video: false, validator: [] },
  ],
  config: {
    default: {
      title: 'Экспедитор',
      fields: 'name,login,password,status,role,images',
      fieldsRO: '',
      fieldsForm: 'name,date_birth,login,images',
    },
  },
  viewForm2: {
    fields: 'name,date_birth,login',
  },
  viewForm2: {
    fields: 'images',
  },
};
export default deliveryMan;
