/** @format 
 * 
 *      "name": "test shop",
      "address": null,
      "phone": null,
      "whatsapp": null,
      "passport": null,
      "patent": null,
      "images": null,
      "rating": null,
      "id": 1,
      "createdon": "2024-03-27T07:52:06.000Z",
      "createdby_id": 1,
      "updatedon": "2024-03-27T07:58:37.000Z",
      "updatedby_id": 1,
      "user_id": null,
      "organization_id": 2,
      "status": 1
*/

const shop = {
  title: 'Магазины',
  routeName: 'shop_view',
  accessName: 'shop',
  api: '/mko/shop',

  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 450 },
    { name: 'city', title: 'Город', type: 'string', filter: { name: 'city', type: 'select', options: 'city' }, sortable: true, width: 250 },
    { name: 'address_fact.address', title: 'Адрес', type: 'string', sortable: true, width: 250 },
    { name: 'phone', title: 'Телефон', type: 'string', sortable: true, width: 120 },
    { name: 'whatsapp', title: 'whatsapp', type: 'string', sortable: true, width: 120 },
    { name: 'rating', title: 'Рейтинг', type: 'string', sortable: true, width: 120 },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_active', sortable: true, width: 120 },
  ],
  list_role1000: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 450 },
    { name: 'city', title: 'Город', type: 'string', filter: { name: 'city', type: 'select', options: 'city' }, sortable: true, width: 250 },
    { name: 'address_fact.address', title: 'Адрес', type: 'string', sortable: true, width: 250 },
    { name: 'phone', title: 'Телефон', type: 'string', sortable: true, width: 120 },
    { name: 'whatsapp', title: 'whatsapp', type: 'string', sortable: true, width: 120 },
    { name: 'rating', title: 'Рейтинг', type: 'string', sortable: true, width: 120 },
    { name: 'organization_id', title: 'Организация', type: 'select', sortable: true, width: 120 },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_active', sortable: true, width: 120 },
  ],
  form: [
    { name: 'name', title: 'Наименование', type: 'string', validator: ['req'], autofocus: true },
    { name: 'address_fact', title: 'Адрес фактический', type: 'address', validator: ['req'] },
    {
      name: 'address_yur',
      title: 'Адрес юридический',
      type: 'address',
      validator: ['req'],
      config: { clone: { field: 'address_fact', title: 'Соовпадает с фактическим' } },
    },
    {
      name: 'address_passport',
      title: 'Адрес контактный владельца',
      type: 'address',
      validator: ['req'],
      config: { clone: { field: 'address_fact', title: 'Соовпадает с фактическим' } },
    },
    { name: 'phone', title: 'Телефон', type: 'phone', validator: ['req'] },
    { name: 'whatsapp', title: 'whatsapp', type: 'phone', validator: ['req', 'tel'], mask: '996*********' },
    { name: 'passport', title: 'Паспорт', type: 'string', validator: ['req'] },
    { name: 'inn', title: 'ИНН', type: 'string', validator: ['req'] },
    { name: 'okpo', title: 'ОКПО', type: 'string', validator: ['req'] },
    { name: 'gni', title: 'ГНИ', type: 'string', validator: ['req'] },
    { name: 'ogrn', title: 'Регистрационный номер', type: 'string', validator: ['req'] },
    { name: 'date_registration', title: 'Дата регистрации', type: 'datestring', validator: ['req'] },
    { name: 'images.interior', title: 'Внутри', type: 'images', video: false, validator: [] },
    { name: 'images.exterior', title: 'Снаружи', type: 'images', video: false, validator: [] },
    { name: 'rating', title: 'Рейтинг', type: 'number' },
    { name: 'type_of_ownership', title: 'Форма собственности', type: 'select', options: 'typeOwnership', validator: [] },
    { name: 'organization_id', title: 'Организация', type: 'selectapi', api: '/users/organization/select', validator: [] },
    { name: 'user_id', title: 'Пользователь', type: 'selectapi', api: '/users/users/select', validator: [] },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_active', default: 0, validator: ['req'] },
    { name: 'data' },
  ],
  viewForm2: {
    fields: 'address_fact,phone,rating',
  },
  config: {
    watch: 'type_of_ownership',
    default: {
      api: '/mko/shop',
      title: 'Магазин',
      fields: 'name,rating,address_fact,phone,whatsapp,rating,organization_id,status,user_id,images.interior,images.exterior,type_of_ownership',
      fieldsRO: '',
      fieldsForm: 'name#10,rating#2,address_fact,type_of_ownership,phone#6,whatsapp#6,images.interior,images.exterior,user_id#6,status#6',
    },
    1: {
      title: 'Магазин (ИП)',
      fields: `name,rating,address_fact,address_yur,address_passport,phone,whatsapp,
      passport,inn,ogrn,date_registration,okpo,gni,
      rating,organization_id,status,user_id,images.interior,images.exterior,type_of_ownership`,
      fieldsRO: 'rating',
      fieldsForm: `name#10,rating#2,address_fact,type_of_ownership,
        address_yur,address_passport,passport#6,inn#6,ogrn#6,date_registration#6,okpo#6,gni#6,
        phone#6,whatsapp#6,images.interior,images.exterior,user_id#6,status#6`,
    },
    2: {
      title: 'Магазин (ОсОО)',
      fields: `name,rating,address_fact,address_yur,phone,whatsapp,passport,images,rating,
        inn,ogrn,okpo,gni,address.address,
        organization_id,status,user_id,images.interior,images.exterior,type_of_ownership`,
      fieldsRO: 'rating',
      fieldsForm: `name#10,rating#2,address_fact,type_of_ownership,address_yur,
      inn#4,address.address#8,okpo#6,gni#6,
      phone#6,whatsapp#6,images.interior,images.exterior,user_id#6,status#6`,
    },
    role_11150: {
      api: '/mko/deliveryman/vendor/shops',
    },
  },
};
export default shop;
