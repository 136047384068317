<template>
  <div>
    <a-form-modelCols
      v-model="data"
      class="pb-1 mx-1 mt-1"
      :model="getFormModel(['services#7', 'note#5'])"
      :values="data"
      :errors="errors"
      :config="{ dense: true, readonly, hideDetails: true }"
      @validate="
        $emit('change');
        submit(true);
      "
    />
  </div>
</template>

<script>
import { getForm, getAccess, libChecks, submitForm } from "@/components/mixings";
export default {
  mixins: [getForm, libChecks, getAccess, submitForm],
  props: {
    value: Object,
    config: {
      type: Object,
      default: () => {
        return { dense: true, hideDetails: "auto" };
      },
    },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      model: [
        { name: "services", title: "Работы", type: "select", dir: "service", dirFilter: { category_id: 28 }, validator: ["req"], chips: true },
        { name: "note", title: "Заметки", type: "string" },
      ],
    };
  },
  computed: {},
  created() {
    this.updateData();
  },
  watch: {
    value(v) {
      this.updateData();
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      let data = this.value;
      if (!data) this.isChanged = true;
      for (const name in data) {
        this.$set(this.data, name, data[name]);
        this.$set(this.errors, name, null);
      }
      this.loading = false;

      this.afterFetchData(data);
    },
    async post(data, api, silent = false) {
      this.loading = true;
      try {
        this.loading = false;
        this.$emit("commit", data);
        this.afterSave(data);
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка при сохранении",
        });
        this.loading = false;
        return;
      }
    },
    commit() {
      this.$emit("input", this.list);
    },
    onClickDel(key) {
      let e = this.list;
      e.splice(key, 1);
      e.forEach((el, i) => {
        el.numb = i + 1;
      });
      this.list = e;
      this.commit();
      this.reset();
      if (this.curError?.id == key) {
        this.curError = {};
      }
    },
  },
};
</script>
