<template>
  <div class="d-flex" style="position: relative">
    <v-autocomplete
      v-if="model.autocomplete"
      outlined
      :items="getSortOptions(model)"
      item-text="text"
      item-value="value"
      item-disabled="hidden"
      menu-props="offset-y"
      :value="value"
      :label="$t(titleValue)"
      :error-messages="error"
      :disabled="disabled"
      :hide-details="config.hideDetails || false"
      :clearable="!readonly"
      :readonly="readonly"
      :dense="config.dense || false"
      @input="$emit('input', $event)"
      :small-chips="model.chips || false"
      :multiple="model.chips || false"
      :deletable-chips="model.chips || false"
      :hide-selected="model.chips || false"
    ></v-autocomplete>
    <v-select
      v-else
      outlined
      :clearable="!readonly"
      :items="getSortOptions(model)"
      item-text="text"
      item-value="value"
      item-disabled="hidden"
      :value="value"
      menu-props="offset-y"
      :label="$t(titleValue)"
      :error-messages="error"
      :disabled="disabled"
      :hide-details="config.hideDetails || false"
      :readonly="readonly"
      :dense="config.dense || false"
      @input="onInput($event)"
      :small-chips="model.chips || false"
      :multiple="model.chips || false"
      :deletable-chips="model.chips || false"
      :hide-selected="model.chips || false"
    />
  </div>
</template>

<script>
import modelOptions from "./../../libs/modelOptions";

export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number, Array],
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },

    model: { type: Object, default: () => {} },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {}, disabled: false },
  },
  data() {
    return {
      cc: 0,
      title: null,
      preText: null,
      toggleSelect: false,
    };
  },
  computed: {
    options() {
      return this.getOptions(this.model) || [];
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    titleValue() {
      return this.title || this.model.title;
    },
  },
  methods: {
    onInput(e) {
      this.$emit("input", e);
      if (this.model.cascade) {
        for (const key in this.model.cascade) {
          const el = this.model.cascade[key];
          if (el.type == "field") {
            let value = this.options.find(f => f["value"] == e)?.[el.value];
            if (value !== undefined) {
              this.$emit("cascade", { field: el.value, value });
            }
          }
        }
      }
    },
    getSortOptions(model) {
      let list = JSON.parse(JSON.stringify(this.options));
      if (model?.dirVar) {
        const key = model.dirVar.key;
        if (this.values) {
          const v = model?.json ? this.values?.data?.[key] : this.values[key];
          const name = model.dirVar.dirName.find(el => {
            return el.value == v;
          });
          this.title = name?.title || null;
          this.preText = name?.preText || null;
        }
      }
      if (this.value && !list.some(list => list.value === this.value) && !Array.isArray(this.value)) {
        list = [{ text: this.value, value: this.value }, ...list];
      }
      return list.sort((a, b) => {
        return (a.text > b.text) - (a.text < b.text);
      });
    },
  },
};
</script>
