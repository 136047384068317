<template>
  <a-input :value="dates" :label="model.title" :error="error">
    <template v-slot:default>
      <v-icon>mdi-calendar</v-icon>
      <div class="d-inline-block">
        <a-input-base-date-picker-range v-model="dates" />
      </div>
    </template>
  </a-input>
</template>

<script>
export default {
  props: {
    value: String,
    values: Object,

    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    dates: {
      get: function () {
        let date = new Date().date;
        let res;
        res = [
          this.values[this.model.dates[0]] ? new Date(this.values[this.model.dates[0]] || date).date : null,
          this.values[this.model.dates[1]] ? new Date(this.values[this.model.dates[1]]).date : null,
        ].join(" - ");
        return res;
      },
      set: function (v) {
        let dates = v.split(" - ").sort();
        if (dates.length == 1) dates.push(dates[0]);
        let obj = {
          [this.model.dates[0]]: new Date(dates[0]).toISOString(),
          [this.model.dates[1]]: new Date(dates[1]).toISOString(),
        };
        this.$emit("input", obj);
        //this.$emit("input", v.split(" - ").sort().join(" - "));
      },
    },
  },
};
</script>
