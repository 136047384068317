<template>
  <div>
    <div class="text-left v-label d-flex">
      <div>{{ model.title }}:</div>
      <v-spacer />
      <v-checkbox v-if="canClone" v-model="isClone" @change="makeClone()" :label="clone.title" dense hide-details class="py-0 my-0" />
    </div>
    <v-row class="mt-1 mb-1" style="overflow-y: auto; overflow-x: hidden">
      <v-col v-for="(el, i) in form" :key="i" cols="12" :sm="el.size" class="py-0">
        <component
          :class="{ 'my-2': config.hideDetails || config.hideDetails == 'auto' }"
          :ref="`input_${el.name}`"
          :is="'a-input-' + el.type"
          :model="getModel(el)"
          :values="data"
          :disabled="el.disabled || isClone || false"
          :value="getValue(el)"
          :config="config"
          :readonly="readonly"
          @input="onInput($event, el)"
          :error="getError(el)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { fillForm, validate } from "@/components/mixings";
import lodash from "lodash";

export default {
  mixins: [fillForm, validate],
  props: {
    value: [String, Number, Object],
    values: { type: Object, default: () => {} },
    model: { type: Object, default: () => {} },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      form: [
        { name: "city", title: "Город", type: "select", options: "city", size: 4, validator: ["req"] },
        { name: "address", title: "Адрес", type: "string", size: 8, validator: ["req"] },
      ],
      loaded: false,
      isClone: false,
      clone: null,
    };
  },
  watch: {
    value: {
      deep: true,
      handler(v, vv) {
        for (const name in v) {
          this.data[name] = v[name];
        }
        this.checkClone();
      },
    },
    values: {
      deep: true,
      handler(v, vv) {
        if (this.canClone && this.isClone) {
          this.checkClone();
        }
      },
    },
  },
  computed: {
    canClone() {
      let clone = null;
      let canClone = false;
      if (this.model?.config?.clone) {
        canClone = true;
        clone = this.model.config.clone;
      }
      this.clone = clone;
      return canClone;
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
  },
  async created() {
    this.loaded = false;
    await this.checkClone();
    //this.fillFormFromModel(this.form);
    this.loaded = true;
  },
  methods: {
    async checkClone() {
      if (!this.canClone) return;
      const clone = this.values[this.clone.field];
      this.isClone = (this.isClone || !this.loaded) && lodash.isEqual(clone, this.data);
    },
    fillForm() {
      for (let el of this.form) {
        this.$set(this.data, el.name, this.value?.[el.name] || null);
        this.$set(this.errors, el.name, null);
      }
      this.fillFormValidators(this.form);
    },
    async fetchData(id) {},
    async onInput(e, el) {
      this.data[el.name] = e;
      if (await this.validateAll(this.data, !false)) {
        this.$emit("input", this.data);
      } else {
        this.$emit("input", null);
      }
    },
    getModel(el) {
      return this.form.find((m) => m.name == el.name);
    },
    getValue(el) {
      if (el.name.indexOf(".") !== -1) {
        let field = el.name.split(".")[0];
        let name = el.name.split(".")[1];
        return this.data?.[field]?.[name];
      }
      return el.json ? this.data.data[el.name] : this.data[el.name];
    },
    getError(el) {
      if (el.calculated === true) {
        let err = [];
        if (el.errorFrom)
          for (let e of el.errorFrom) {
            if (this.errors?.[e])
              err.push(
                this.model.find((m) => {
                  return m.name == e;
                })?.title +
                  ": " +
                  this.errors?.[e]
              );
          }
        return err.join(" , ");
      }

      if (el.type == "ref") return this.errors[el.name + "_id"];
      return this.errors[el.name];
    },
    makeClone() {
      let clone = JSON.parse(JSON.stringify(this.values?.[this.clone.field] || null));
      if (!clone) {
        this.isClone = false;
        return;
      }
      this.$emit("input", clone);
      //console.log("make clone", clone);
    },
  },
};
</script>
