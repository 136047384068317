/** @format 
 * 
      "images": null,
      "id": 1,
      "createdon": "2024-03-27T07:52:06.000Z",
      "createdby_id": 1,
      "updatedon": "2024-03-27T07:58:37.000Z",
      "updatedby_id": 1,
      "user_id": null,
      "organization_id": 2,
      "status": 1
*/

const payment = {
  title: 'Поставки',
  routeName: 'payment',
  accessName: 'payment',
  api: '/mko/payment',

  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'createdon', title: 'Дата', type: 'datetime', sortable: true, width: 120 },
    {
      name: 'createdby_id',
      title: 'Автор',
      type: 'select',
      dir: 'user',
      filter: { name: 'createdby_id', type: 'selectapi', api: '/users/users/select' },
      sortable: true,
      width: 150,
    },
    { name: 'amount', title: 'amount', type: 'number', sortable: true, width: 120 },
    {
      name: 'organization_id',
      title: 'Организация',
      type: 'select',
      filter: { name: 'organization_id', type: 'selectapi', api: '/users/organization/select' },

      sortable: true,
      width: 120,
    },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_active', sortable: true, width: 120 },
  ],
  form: [
    { name: 'amount', title: 'amount', type: 'number' },
    { name: 'images', title: 'Фото', type: 'images', validator: [] },
    { name: 'organization_id', title: 'Организация', type: 'selectapi', api: '/users/organization/select', validator: [] },
    { name: 'user_id', title: 'Пользователь', type: 'selectapi', api: '/users/users/select', validator: [] },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_active', default: 0, validator: ['req'] },
    { name: 'data' },
  ],

  config: {
    default: {
      title: 'Платеж',
      fields: 'amount,images,organization_id,status,user_id',
      fieldsRO: '',
      fieldsForm: 'amount,images,user_id#6,status#6',
    },
  },
};
export default payment;
