<template>
  <div :style="style">{{ v }}</div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    model: {
      type: Object,
      default: {},
    },
  },
  computed: {
    v() {
      if (!this.value) return "";
      const d = new Date(this.value);
      return d.toLocaleDateString("ru-RU") + " " + d.toLocaleTimeString("ru-RU");
    },
    style() {
      let style = "";
      if (this.model?.style) style = this.model?.style;
      return style;
    },
  },
};
</script>
