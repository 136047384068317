/** @format */

export default {
  data() {
    return {
      loading: false,
      data: [],
      sort: [
        {
          key: 'id',
          order: 'DESC',
        },
      ],
      pager: {
        page: 0,
        count: 0,
        limit: 50,
      },
      filters: {},
      where: '',
    };
  },
  methods: {
    async updateData() {
      await this.fetchData();
    },
    async updateRowData(id) {
      let f = JSON.parse(JSON.stringify(this.filters));
      f.id = id;
      let sql = this.query.sql;
      let where = this.getWhere(this.filters);
      sql += where;
      const d = await this.$axios.post('/accounting/records/report-sql', { q: sql, params: { filters: f } });
      if (d.data && d.data.data) {
        let rowId = this.data.findIndex((el) => el.id == id);
        if (rowId != -1) {
          const data = d.data.data.find((el) => {
            return el.id == id;
          });
          if (!data) {
            this.$delete(this.data, rowId);
            //this.data.slice(rowId, 1);
          } else {
            for (const key of Object.keys(this.data[rowId])) {
              this.data[rowId][key] = data?.[key] || null;
            }
          }
        }
      }
    },
    async fetchData(append = false) {
      if (!this.query.sql) return;
      this.loading = true;
      let sql =
        this.query.sql +
        `
      `;
      let page = this.pager.page || 0;
      let where = this.getWhere(this.filters);
      let order = this.getOrder(this.sort);
      let group = this.query?.group || '';
      let limit = '';
      let offset = '';
      let countSelect = 'count(1) as count';
      if (page === 0) {
        if (this.query?.footer) {
          this.query.footer.forEach((el) => {
            countSelect += `, sum(${el.name}) as ${el.name}`;
            if (!this.pager?.[el.name]) {
              console.log('pager', this.pager, el.name);
              this.$set(this.pager, el.name, null);
            }
          });
        }
        //console.log({ sql, where, group, order });
        let count = await this.$axios.post('/accounting/records/report-sql', {
          q: `select ${countSelect}  from (${sql + where + group}) as cc`,
        });
        this.pager.count = count.data.data[0].count || null;

        if (this.query?.footer) {
          this.query.footer.forEach((el) => {
            if (!this.pager?.[el.name]) {
              this.$set(this.pager, el.name, null);
            }
            this.pager[el.name] = count.data.data[0][el.name];
          });
        }
      }
      if (this.pager.limit) {
        limit = ' limit ' + this.pager.limit;
        offset = ' offset ' + this.pager.page * this.pager.limit;
      }
      let q = sql + where + group + order + limit + offset;
      // console.log('query', q);

      let response = await this.$axios.post('/accounting/records/report-sql', {
        q,
      });
      this.loading = false;
      this.pager = response.data.pager || this.pager;
      let d = response.data.data;
      try {
        this.model
          .filter((el) => {
            return el.formula ? true : false;
          })
          .forEach((m) => {
            for (let i = 0; i < d.length; i++) {
              const row = d[i];
              let res = 0;
              if (m.formula) {
                let func;
                if (typeof m.formula == 'function') func = m.formula;
                if (typeof m.formula == 'string') func = eval(m.formula);
                res = func(row);
              }
              d[i][m.name] = res;
            }
          });
      } catch (error) {
        console.log('ERROR', error);
      }
      if (append) {
        this.data = this.data.concat(d);
      } else {
        this.data = d;
      }
      this.$emit('dataUpdated', response.data);
      let lastRow = this.data.slice(-1)[0];
      this.$emit('lastRow', lastRow);
      this.$emit('loadData', this.data);
      await this.afterFetchData(response);
    },
    async afterFetchData(response) {},

    getWhere(f, w = 0) {
      let where = '';
      for (const key in f) {
        if (typeof f[key] === 'string' && f[key] !== '') {
          where += where ? ' and ' : '';
          where += `${key} = '${f[key]}'`;
        } else if (typeof f[key] === 'number' && f[key] !== '') {
          where += where ? ' and ' : '';
          where += `${key} = ${f[key]}`;
        } else if (Array.isArray(f[key])) {
          let a = '';
          f[key].forEach((el) => {
            a += a ? ' or ' : '';
            a += this.getWhere({ [key]: el }, 1);
          });
          where += where ? ' and ' : '';
          where += `(${a})`;
        } else if (typeof f[key] === 'object') {
          where += where ? ' and ' : '';
          where += `${key} ${f[key].condition} '${f[key].value}' `;
        }
      }
      console.log('get where ', f, where);
      if (!w) {
        if (this.query?.where) {
          where += where ? ' and ' : '';
          where += this.query.where;
        }
        where = where ? 'WHERE ' + where : '';
      }
      return where;
    },
    getOrder(o) {
      let order = '';
      if (!Array.isArray(o)) {
        o = [o];
      }
      for (const el of o) {
        if (!el?.key) continue;
        order += order ? ' , ' : '';
        order += `${el.key} ${el.order}`;
      }
      if (this.query?.order) {
        order += order ? ' , ' : '';
        order += this.query.order;
      }
      return order ? ' ORDER BY ' + order : '';
    },
  },
};
