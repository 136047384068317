<template>
  <v-list-item link :to="value.route">
    <v-list-item-icon>
      <v-icon v-if="!sublist">{{ value.icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-title style="font-size: 1rem; font-weight: 300">
      <div>
        <v-badge color="red" :value="items && value.badge && 1 == 2" :content="items" overlap>
          {{ $t(getTitle(value)) }}
          <v-badge v-if="value.badge" color="green" :value="comments" :content="comments" inline></v-badge>
          <v-badge v-if="value.badge" color="red" :value="items" :content="items" inline></v-badge>
        </v-badge>
      </div>
    </v-list-item-title>
    <v-list-item-icon v-if="sublist" class="ml-0">
      <v-icon>{{ value.icon }}</v-icon>
    </v-list-item-icon>
  </v-list-item>
</template>

<script>
//import mdiOfficeBuildingPlusOutline from "@assets/icons/office-building-plus-outline";
export default {
  props: {
    value: Object,
    sublist: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    comments() {
      return this.$store.getters["state/getBadges"](this.value.badge, "comments");
      //let res = this.$store.getters["state/getBadges"];
      let res = this.$store.state.state.badges;
      console.log("computed badges", this.value.badge, res);
      return res?.[this.value.badge]?.comments;
    },
    items() {
      //let res = this.$store.getters["state/getBadges"];
      let res = this.$store.state.state.badges;
      return res?.[this.value.badge]?.items || 0;
    },
  },
  methods: {
    getTitle(el) {
      let name = el?.route?.name || el?.accessName || "";
      let res = el.name;
      try {
        let tmp = JSON.parse(JSON.stringify(this.$store.getters["config/get"].config.access?.menu?.[name] || {}));
        if (tmp?.title?.[this.$root.profile.role]) res = tmp?.title?.[this.$root.profile.role];
      } catch (error) {}
      return res;
    },
    getItemsCount(n) {
      let res = this.badges?.[n] || {};
      return res?.items || 0;
    },
    getCommentsCount(n) {
      let res = this.badges?.[n] || {};
      return res?.comments || 0;
    },
  },
};
</script>
