<template>
  <div><a-input-base-editor-index class="py-2" :value="value" :options="editorOption" @input="onChange($event)" :rows="model.rows" /></div>
</template>

<script>
export default {
  components: {},
  props: {
    value: [String, Number],
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editorOption: {},
    };
  },
  methods: {
    onChange(e) {
      //console.log("input", e);
      this.$emit("input", e);
    },
  },
};
</script>
