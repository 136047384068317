/** @format */

export default {
  data() {
    return {
      loading: false,
      data: [],
      sort: [
        {
          key: 'id',
          order: 'DESC', 
        },
      ],
      pager: {
        page: 0,
        count: 0,
        limit: 50,
      },
      filters: {},
      where: '',
    };
  },
  methods: {
    async updateData() {
      await this.fetchData();
    },
    async updateRowData(id) {
      let f = JSON.parse(JSON.stringify(this.filters));
      f.id = id;
      const d = await this.$axios.get(this.api, {
        params: { filters: f },
      });
      if (d.data && d.data.data) {
        let rowId = this.data.findIndex((el) => el.id == id);
        if (rowId != -1) {
          const data = d.data.data.find((el) => {
            return el.id == id;
          });
          if (!data) {
            this.$delete(this.data, rowId);
            //this.data.slice(rowId, 1);
          } else {
            for (const key of Object.keys(this.data[rowId])) {
              if (key == '_updated') {
              } else this.data[rowId][key] = data?.[key];
            }

            this.data[rowId]['_updated']++;
            console.log('_update', rowId, this.data[rowId]['_updated']);
          }
        }
      }
    },
    async beforeFetchData(response) {},
    async fetchData(append = false) {
      await this.beforeFetchData(response);
      this.loading = true;
      let where = this.where || this.defaults?.where || {};
      let filters = Object.assign({}, this.filters, where);
      //filters = Object.assign({}, where, this.filters);
      //filters = Object.assign({}, this.filters);
      let params = { sort: JSON.stringify(this.sort), filters, pager: this.pager, where: {} };
      let response = await this.$axios.get(this.api, { params });
      this.loading = false;
      this.pager = response.data.pager;
      let d = response.data.data;
      d = d.map((el) => {
        el['_updated'] = 0;
        return el;
      });
      try {
        this.model
          .filter((el) => {
            return el.formula ? true : false;
          })
          .forEach((m) => {
            for (let i = 0; i < d.length; i++) {
              const row = d[i];
              let res = 0;
              if (m.formula) {
                let f = m.formula;
                Object.defineProperty(d[i], m.name, {
                  get: function () {
                    let res = 0;
                    let func;
                    if (typeof f == 'function') func = f;
                    if (typeof f == 'string') func = eval(f);
                    res = func(this);
                    return res;
                  },
                });
              }
            }
          });
      } catch (error) {
        console.log('ERROR', error);
      }
      if (append) {
        this.data = this.data.concat(d);
      } else {
        this.data = d;
      }
      this.$emit('dataUpdated', response.data);
      let lastRow = this.data.slice(-1)[0];
      this.$emit('lastRow', lastRow);
      this.$emit('loadData', this.data);
      //console.log({ api: this.api, data: response.data });
      await this.afterFetchData(response);
    },
    async afterFetchData(response) {},
  },
};
