<template>
  <div>
    <v-text-field v-if="mode == 'edit'" v-model="data[model.name]" dense hide-details class="no-underline" type="number" @blur="mode = 'view'"></v-text-field>
    <a-view-number v-else :value="value" :style="style" @click="onClick()" />
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    model: { type: Object, default: () => {} },
  },
  data() {
    return {
      mode: "view",
    };
  },
  computed: {
    style() {
      let style = "";
      if (this.model?.style) style = this.model?.style;
      return style;
    },
  },
  methods: {
    onClick() {
      console.log("clic");
      if (this.model.edit) this.mode = "edit";
    },
  },
};
</script>
