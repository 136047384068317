/** @format */

const info = {
  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'data.group', title: 'Раздел', type: 'select', options: 'information_chapters', sortable: !true },
    { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 450 },
  ],
  form: [
    { name: 'name', title: 'Наименование', type: 'string', validator: ['req'], autofocus: true },
    { name: 'data.group', title: 'Раздел', type: 'select', options: 'information_chapters' },
    { name: 'data.role', title: 'Доступ для ролей', type: 'string' },
    { name: 'data.section', title: 'Назначение', type: 'string' },
    { name: 'content', title: 'Содержимое', type: 'editor2', validator: ['req'], rows: -1 },
    { name: 'content1', title: 'Содержимое', type: 'text', validator: ['req'], hidden: true },
  ],

  config: {
    form: {
      title: 'Инфо',
      fields: 'name,data.group,data.role,data.section,content',
      fieldsRO: '',
      fieldsForm: 'name,data.group#4,data.role#4,data.section#4,content',
    },
  },
  group: {
    api: '/mechti/information_group',
    list: [
      { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
      { name: 'is_parent', title: ' ', type: '', sortable: true, width: 40 },
      { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 450 },
      { name: 'information_data.group', title: 'Раздел', type: 'select', options: 'information_chapters', sortable: false, searchable: false },
      { name: 'parent_id', title: 'Родитель', type: 'number', sortable: true },
    ],
    form: [
      { name: 'name', title: 'Наименование', type: 'string', validator: ['req'] },
      { name: 'information_data.group', title: 'Раздел', type: 'select', options: 'information_chapters' },
      { name: 'information_data.role', title: 'Доступ для ролей', type: 'string' },
      { name: 'information_data.section', title: 'Назначение', type: 'string' },
      { name: 'images', title: 'Изображение', type: 'images', video: false },
      { name: 'introtext', title: 'Краткое описание', type: 'text' },
      { name: 'content', title: 'Содержимое', type: 'editor2' },

      { name: 'information_data.info', title: 'Инструкции', type: 'info' },
      { name: 'parent_id', title: 'Родитель', type: 'selectapi', api: '/mechti/information_group/select?filters={"is_parent":1}', default: 0 },
      { name: 'is_parent', title: 'Родитель', type: 'switcher', default: 0 },
    ],
    config: {
      watch: 'is_parent',
      0: {
        title: 'Инфо',
        fields: 'images,name,information_data.group,information_data.role,information_data.section,content,information_data.info,parent_id,is_parent,introtext,parent',
        fieldsRO: '',
        fieldsForm: 'name,information_data.group#4,information_data.role#4,information_data.section#4,is_parent#2,parent_id#10,introtext,images,information_data.info',
      },
      1: {
        title: 'Инфо',
        fields: 'images,name,information_data.group,information_data.role,information_data.section,parent_id,is_parent,introtext,parent',
        fieldsRO: '',
        fieldsForm: 'name,information_data.group#4,information_data.role#4,information_data.section#4,is_parent#2,parent_id#10,introtext,images',
      },
    },
  },
};
export default info;
