<template>
  <div>
    <quill-editor class="py-2" :content="value" :options="editorOption" @change="onChange($event)" />
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
  },
  props: {
    value: [String, Number],
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editorOption: {},
    };
  },
  methods: {
    onChange(e) {
      this.$emit("input", e.html);
    },
  },
};
</script>
