<template>
  <div>
    <textarea :value="value" style="display: none"></textarea>
    <div class="v-text-editor" :class="{ 'v-text-editor-full': fullscr }">
      <div class="v-text-editor-toolbar">
        <div class="v-text-editor-toolbar-group" v-for="(gr, i) in toolbar" :key="i">
          <div class="v-text-btn-wrapper" v-for="(el, b) in gr" :key="b">
            <template v-if="buttons[el].command == 'submenu'">
              <button
                class="v-text-btn"
                v-on:click="buttons[el].show ? (buttons[el].show = false) : (buttons[el].show = true)"
                :title="buttons[el].title"
                v-html="buttons[el].text"
              ></button>
              <div v-if="buttons[el].show" class="v-text-editor-toolbar-submenu" :class="buttons[el].type">
                <div v-for="(sub, si) in buttons[el].value" :key="si">
                  <button
                    class="v-text-btn"
                    v-on:click="
                      buttons[el].show = false;
                      genAction(sub);
                    "
                    :title="sub.title"
                    v-html="sub.text"
                    :style="{ background: sub.value }"
                  ></button>
                </div>
              </div>
            </template>
            <template v-else>
              <button class="v-text-btn" v-on:click="genAction(buttons[el])" :title="buttons[el].title" v-html="buttons[el].text"></button>
            </template>
          </div>
        </div>
      </div>
      <div style="position: relative" :style="rows && !fullscr ? 'height: ' + (rows < 0 ? '100%' : rows * 20 + 'px') : ''">
        <div
          :style="`${rows < 0 ? 'height: 100%; ' : 'position: absolute; '} bottom: 0; top: 0; left: 0; right: 0; `"
          ref="content"
          class="v-text-editor-content"
          contenteditable
          v-on:blur="emitChange"
          v-on:keyup="emitChange"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import buttons from "./buttons";

export default {
  props: {
    value: {
      type: String,
      default: "<div></div>",
    },
    rows: {
      type: Number,
      default: 20,
    },
    toolbar: {
      type: Array,
      default: () => [
        ["formats", "bold", "italic", "underline", "subscript", "superscript", "strikeThrough", "backColor", "foreColor", "fontSize", "fontName"],
        ["left", "right", "center", "indent", "outdent"],
        ["insertImage", "createlink", "unlink"],
        ["insertOrderedList", "insertUnorderedList", "insertHorizontalRule"],
        //    ['undo', 'redo'],
        ["cut", "copy"],
        ["selectAll", "removeFormat"],
        ["code", "fullscr"],
      ],
    },
  },
  data: function () {
    return {
      content: null,
      fullscr: false,
      viewcode: false,
      buttons: buttons,
    };
  },
  watch: {
    value: {
      handler(v, oldV) {
        if (v === null) this.$refs.content.innerHTML = "";
        else if (oldV === null) this.$refs.content.innerHTML = v;
      },
    },
  },
  methods: {
    emitChange(ev) {
      if (this.viewcode) {
        var val = ev.target.innerText;
      } else {
        var val = ev.target.innerHTML;
      }
      this.$emit("change-model", val);
      this.$emit("input", val);
    },
    genAction(btn) {
      switch (btn.command) {
        case "fullscr":
          if (this.fullscr) {
            this.fullscr = false;
          } else {
            this.fullscr = true;
          }
          break;
        case "code":
          if (this.viewcode) {
            this.viewcode = false;
            this.$refs.content.innerHTML = this.value;
          } else {
            this.viewcode = true;
            this.$refs.content.innerText = this.value;
          }
          break;
        default:
          if (!this.viewcode) {
            if (btn.command == "createlink" || btn.command == "insertImage") {
              btn.value = prompt("Enter the link here: ", "");
            }
            this.$refs.content.focus();
            document.execCommand(btn.command, false, btn.value);
            this.$emit("change-model", this.$refs.content.innerHTML);
            this.$emit("input", this.$refs.content.innerHTML);
          }
      }
    },
  },
  created() {},
  destroyed() {},
};
</script>

<style lang="scss">
.v-text-editor {
  text-align: left;
  background: #fff;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 3px;

  .v-text-editor-content {
    resize: vertical;
    outline: none;
    min-height: 150px;
    overflow: auto;
    padding: 1em;
    height: 100%;
  }

  .v-text-editor-toolbar {
    border-bottom: 1px solid #eee;

    .v-text-editor-toolbar-group {
      display: inline-block;
      border-right: 1px solid #aaa;
      border-bottom: 1px solid #aaa;
    }

    .v-text-btn {
      outline: none;
      background: none;
      color: #999;
      padding: 10px;
      width: 36px;
      text-align: center;
      font-size: 16px;
      border: none;
      cursor: pointer;
      border-right: 1px solid #efefef;

      &:hover {
        background: #eee;
        color: #555;
      }
    }
  }
}

.v-text-btn-wrapper {
  display: inline-block;
  position: relative;

  .v-text-editor-toolbar-submenu {
    position: absolute;
    background: #fff;
    z-index: 20;

    &.color {
      width: 300px;

      div {
        display: inline-block;
      }
    }

    &.fontname {
      button {
        width: auto;
        text-align: left;
      }
    }
  }
}

.v-text-editor-full {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20000;
}
</style>
