<template>
  <div>
    <a-view-chip
      :value="value"
      :options="model.options"
      :hideText="model.hideText"
      :showText="values.id"
    />
  </div>
</template>
 
<script>
export default {
  props: {
    value: [String, Number],
    values: [Object],
    model: {
      type: Object,
      default: {},
    },
  },
};
</script>