<template>
  <v-row>
    <v-col v-for="(el, i) in model" :key="i" cols="12" class="pb-0 pt-1 mb-2 px-1" :sm="getSize(el)" v-if="el.type && el.hidden !== true">
      <component
        :is="'a-input-' + el.type"
        :model="el"
        :values="data"
        :disabled="el.disabled || false"
        :value="getValue(el)"
        :config="config"
        :readonly="readonly"
        @input="onInput($event, el)"
        @fieldData="$emit('fieldData', { name: el.name, value: $event })"
        @selected="$emit('selected', { name: el.name, value: $event })"
        @commit="$emit('commit', { event: $event, el })"
        :error="getError(el)"
      ></component>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: Object,
    model: Array,
    errors: Object,
    config: {
      type: Object,
      default: () => {
        return { dense: true, hideDetails: false };
      },
    },
    readonly: { type: Boolean, default: false },
  },
  computed: {
    data: {
      set(v) {
        this.$emit("input", v);
      },
      get() {
        return this.value;
      },
    },
  },

  methods: {
    getSize(el) {
      return el.size || 12;
    },
    getValue(el) {
      if (el.name.indexOf(".") !== -1) {
        let field = el.name.split(".")[0];
        let name = el.name.split(".")[1];
        return this.data?.[field]?.[name];
      }
      return el.json ? this.data.data[el.name] : this.data[el.name];
    },
    getError(el) {
      if (el.calculated === true) {
        let err = [];
        if (el.errorFrom)
          for (let e of el.errorFrom) {
            if (this.errors?.[e])
              err.push(
                this.model.find((m) => {
                  return m.name == e;
                })?.title +
                  ": " +
                  this.errors?.[e]
              );
          }
        return err.join(" , ");
      }

      if (el.type == "ref") return this.errors[el.name + "_id"];
      return this.errors[el.name];
    },
    async onInput(e, el) {
      let parent = "";

      if (el.json) {
        let field = el?.jsonField || "data";
        let data = JSON.parse(JSON.stringify(this.data[field]));
        data[el.name] = e;
        this.data[field] = data;
      } else if (el.type == "filesdata") {
        let del = JSON.parse(JSON.stringify(this.data["filesRemove"] || []));
        let add = JSON.parse(JSON.stringify(this.data["filesAdd"] || []));
        let d = JSON.parse(JSON.stringify(this.data["filesData"] || []));
        for (const file of e) {
          if (file.type == "add") {
            d.push(file);
            add.push(file);
          }
          if (file.type == "del") {
            d = d.filter((f) => {
              return f.upload_id !== file.upload_id;
            });
            if (file?.id) {
              del.push({ upload_id: file.upload_id });
            } else {
              add = add.filter((f) => {
                return f.upload_id !== file.upload_id;
              });
            }
          }
        }
        this.data[el.name] = e.filter((f) => {
          return f.type !== "del";
        });
        this.data["filesData"] = d;
        this.data["filesAdd"] = add;
        this.data["filesRemove"] = del;
      } else if (el.name.indexOf(".") !== -1) {
        let field = el.name.split(".")[0];
        parent = field;
        let name = el.name.split(".")[1];
        let data = JSON.parse(JSON.stringify(this.data[field]));
        data[name] = e;
        this.data[field] = data;
      } else if (Array.isArray(e)) {
        this.data[el.name] = e;
      } else if (typeof e === "object" && e !== null) {
        if (this.data[el.name] !== null) {
          Object.assign(this.data, e);
        } else {
          this.data[el.name] = e;
        }
        //Object.assign(this.data, e);
      } else {
        this.data[el.name] = e;
      }
      if (el.calculated === true) {
        let err = [];
        if (el.errorFrom)
          for (let e of el.errorFrom) {
            this.$emit("validate", e);
          }
      }
      this.$emit("validate", el.name);
    },

  },
};
</script>
