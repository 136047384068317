/** @format */
const main = [
  {
    path: '/shop',
    name: 'shop',
    meta: { auth: 10, title: 'Магазины' },
    component: () => {
      return import('@/views/shop.vue');
    },
  },
  {
    path: '/shop/:id',
    name: 'shop_view',
    meta: { auth: 10, title: 'Магазин' },
    component: () => {
      return import('@/views/views/shopView.vue');
    },
  },
  {
    path: '/my-shop',
    name: 'my-shop',
    meta: { auth: 10, title: 'Магазины' },
    component: () => {
      return import('@/views/vendorShop.vue');
    },
  },
  {
    path: '/vendor',
    name: 'vendor',
    meta: { auth: 10, title: 'Поставщики' },
    component: () => {
      return import('@/views/vendor.vue');
    },
  },
  {
    path: '/vendor/:id',
    name: 'vendor_view',
    meta: { auth: 10, title: 'Поставщик' },
    component: () => {
      return import('@/views/views/vendorView.vue');
    },
  },
  {
    path: '/credit-org',
    name: 'credit-org',
    meta: { auth: 10, title: 'Кредитные огранизации' },
    component: () => {
      return import('@/views/creditOrg.vue');
    },
  },
  {
    path: '/supply',
    name: 'supply',
    meta: { auth: 10, title: 'Поставки' },
    component: () => {
      return import('@/views/supply.vue');
    },
  },
  {
    path: '/payment',
    name: 'payment',
    meta: { auth: 10, title: 'Платежи' },
    component: () => {
      return import('@/views/payment.vue');
    },
  },
  {
    path: '/delivery-man',
    name: 'deliveryMan',
    meta: { auth: 10, title: 'Экспедиторы' },
    component: () => {
      return import('@/views/deliveryMan.vue');
    },
  },
];

const settings = [
  {
    path: '/users',
    name: 'users',
    meta: { auth: 10, title: 'Пользователи' },
    component: () => {
      return import('@/views/users.vue');
    },
  },
  {
    path: '/organizations',
    name: 'organizations',
    meta: { auth: 10, title: 'Организации' },

    component: () => {
      return import('@/views/organizations.vue');
    },
  },

  {
    path: '/settings',
    name: 'settings',
    meta: { auth: 10, title: 'Настройки' },
    component: () => {
      return import('@/views/settings.vue');
    },
  },
  {
    path: '/uploadsGroup',
    name: 'uploadsGroup',
    meta: { auth: 10, title: 'Группы доступа' },
    component: () => {
      return import('@/views/groupFilesAccess.vue');
    },
  },
  {
    path: '/uploads-admin',
    name: 'uploads-admin',
    meta: { auth: 10, title: 'Загрузки' },
    component: () => {
      return import('@/views/uploadsAdmin.vue');
    },
  },
  {
    path: '/dirs',
    name: 'dirs',
    meta: { auth: 10, title: 'Справочники' },
    props: (route) => ({ dir: route.query.dir, ...route.params }),
    component: () => {
      return import('@/views/dirs.vue');
    },
  },
];
const routes = [
  {
    path: '/index',
    name: 'index',
    meta: { auth: 1, title: 'Главная' },
    component: () => {
      return import('@/views/index.vue');
    },
  },
];

export default {
  routes: [...routes, ...settings, ...main],
};
