/** @format */
const roles = [
  { value: 10, text: 'Клиент' },
  { value: 20, text: 'Инвестор' },
  { value: 30, text: 'Менеджер по продажам' },
  { value: 40, text: 'Снабжение' },
  { value: 45, text: 'Мебельный отдел' },
  { value: 50, text: 'Технадзор' },
  { value: 55, text: 'Дизайнер' },
  { value: 56, text: 'Реклама' },
  { value: 60, text: 'Менеджер проектов' },
  { value: 70, text: 'Юрист' },
  { value: 80, text: 'Бухгалтерия' },
  { value: 85, text: 'Финансовый директор' },
  { value: 90, text: 'Администратор' },
  { value: 1000, text: 'Супер администратор' },
];
const status = [
  { value: 0, text: 'Новое', next: [1, 10, 2], action: '', field: 'status' },
  { value: 1, text: 'Рассмотрение', next: [-1, 10, 2], action: 'На рассмотрение', field: 'status' },
  { value: -1, text: 'Отменено', next: [1], action: 'Отклонить предложение', field: 'status' },
  { value: 10, text: 'В работе', next: [-2, 90], action: 'Принять в работу', field: 'status', func: '' },
  { value: -2, text: 'Отменено', next: [10, 1], action: 'Отменить работу', field: 'status' },
  { value: 90, text: 'Завершен', next: [10], action: 'Завершено', field: 'status' },
  { value: 2, text: 'Фонарик', next: [-3], action: 'Перевести в "фонарик"', field: 'status' },
  { value: -3, text: 'Отменен фонарик', next: [2], action: 'Убрать "фонарик"', field: 'status' },
];
const statusFin = [
  { value: 0, text: 'Новое', next: [1], action: '', field: 'status_fin' },
  { value: 1, text: 'На продаже', next: [-1, 2], action: 'Выставить на продажу', field: 'status_fin' },
  { value: -1, text: 'Снято с продажи', next: [1], action: 'Снять с продажи', field: 'status_fin' },
  { value: 2, text: 'Продано', next: [2], action: 'Продать', field: 'status_fin', func: 'statusSale' },
];
const statusTask = [
  { value: -1, type: 'task', text: 'Настройка', next: [], action: '' },
  { value: 0, type: 'task', text: 'Новая задача', next: [1, -2], action: '' },
  { value: 1, type: 'task', text: 'В работе', next: [2, -2, 3], action: 'В работу' },
  { value: 2, type: 'task', text: 'На проверке', next: [3, -2], action: 'На проверку', color: '#e5afeb' },
  { value: 3, type: 'task', text: 'Выполнена', next: [], action: 'Выполнено' },
  { value: -2, type: 'task', text: 'Отменена', next: [], action: 'Отменить', color: '#ebafb4' },
  { value: 100, type: 'work', text: 'Новая задача', next: [101, 199], action: '' },
  { value: 101, type: 'work', text: 'В работе', next: [102, 199], action: 'В работу' },
  { value: 102, type: 'work', text: 'Выполнена', next: [], action: 'Выполнено' },
  { value: 199, type: 'work', text: 'Отменено', next: [], action: 'Отменить' },
  { value: 200, type: 'goods', text: 'Новая задача', next: [201, 299], action: '' },
  { value: 201, type: 'goods', text: 'Ожидает закупа', next: [202, 299], action: 'Принять' },
  { value: 205, type: 'goods', text: 'Ожидает перезакупа', next: [202, 299], action: 'На перезакуп' },
  { value: 202, type: 'goods', text: 'Закуплен (не доставлен)', next: [203, 204, 205], action: 'Закупить', func: 'statusBuyGoods' },
  { value: 203, type: 'goods', text: 'На складе', next: [204], action: 'Доставлено на склад' },
  { value: 204, type: 'goods', text: 'На объекте', next: [], action: 'Доставлено на объект' },
  { value: 299, type: 'goods', text: 'Отменено', next: [], action: 'Отменить' },
]; //0,1,2,100,101,201,202,205

const type1 = [
  { value: 0, text: 'Инвестор' },
  { value: 1, text: 'Клиент' },
  { value: 2, text: 'Собственный' },
];

export { status, statusFin, statusTask, roles };
