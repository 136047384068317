/** @format */

import Vue from 'vue';
import App from '@/App.vue';
import vuetify from './libs/vuetify';
import router from './libs/router';

import store from './store/index';
import config from './config/config';
import PortalVue from 'portal-vue';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import VueFullscreen from 'vue-fullscreen';
import 'element-ui/lib/theme-chalk/index.css';
import './libs/registerComponents';
import './libs/axios';
import './libs/translations';
import './libs/date';
import './libs/array';
import './libs/string';
import './registerServiceWorker';
import VueHtmlToPaper from 'vue-html-to-paper';
import VueQrcode from '@chenfengyuan/vue-qrcode';
Vue.component(VueQrcode.name, VueQrcode);

Vue.config.productionTip = false;
Vue.use(ElementUI, { locale });
Vue.use(PortalVue);
Vue.use(VueFullscreen);
const options = {
  name: '_blank',
  specs: ['fullscreen=no', 'titlebar=no', 'scrollbars=no'],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
};

Vue.use(VueHtmlToPaper, options);
const app = new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
  el: '#app',
  data: {
    leftMenuDrawer: null,
    dialogShow: false,
    lang: 'ru',
    title: '',
    websocketWorking: false,
    websocket: null,
    websocketData: {},
    windowSize: { contentHeight: 0, innerHeight: 0 },
    filename: '',
  },
  computed: {
    version() {
      return this.$store.getters['config/getVersion'];
    },
    filters() {
      return this.$store.getters['config/getSessionFilter'];
    },
    config() {
      return config;
    },
    profile() {
      return this.$store.getters['auth/profile'];
    },
    roles() {
      return this.dirs.roles;
      return this.$store.getters['config/get'].models.users.roles;
    },
    taskStatuses() {
      return this.$store.getters['config/get'].models.tasks.statuses;
    },
    interface() {
      return this.$store.getters['config/get'].config.interface;
    },
    typeClick() {
      return this.$store.getters['config/getTypeClick'];
    },
    dirs() {
      return this.$store.getters['config/getDirs'];
    },
  },
  created() {
    this.$vuetify.lang.current = this.lang;
  },
  mounted() {},
  methods: {
    log: function (info, type = '', level = '') {
      console.log(info);
    },

    async login(data) {
      await this.$store.dispatch('auth/login', {
        login: data.login,
        password: data.password,
      });
    },
    async logout() {
      await this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },

    setSessionFilter(n, v) {
      this.$store.commit('config/setSessionFilter', {
        name: n,
        value: v,
      });
    },
    getSessionFilter(n) {
      const f = this.filters?.[n] || null;
      return f;
    },
  },
});
