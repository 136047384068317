/** @format */
import { status, statusFin } from '@/config/modules/libs';

export default {
  created() {},
  destroyed() {},
  computed: {
    statusItems() {
      let items = [];
      let res = [];
      if (this.statusCurrent?.['next']) {
        let arr = this.statusCurrent['next'];
        res = status.filter((s) => {
          return arr.includes(s.value);
        });
      }
      items = JSON.parse(JSON.stringify(res));
      let fin = this.data.status_fin || 0;

      let idx = statusFin.findIndex((el) => {
        return el.value == fin;
      });
      if (idx != -1) {
        res = statusFin.filter((s) => {
          return statusFin[idx]['next'].includes(s.value);
        });
        if (res) items = items.concat(JSON.parse(JSON.stringify(res)));
      }

      return items;
    },

    statusCurrent() {
      let idx = status.findIndex((el) => {
        return el.value == this.data.status;
      });
      if (idx == -1) return {};
      return JSON.parse(JSON.stringify(status[idx]));
    },
  },
  methods: {
    getStatus(type) {
      let idx = this.statuses.findIndex((el) => {
        if (el.formula) {
          let func = eval(el.formula);
          return func(this.data[type]);
        }
        return el.value == this.data[type] && el.field == type;
      });
      if (idx == -1) return {};
      return JSON.parse(JSON.stringify(this.statuses[idx]));
    },
    async clickStatus(status) {
      let ok = false;
      ok = await this.$refs.confirmDialog.show({
        title: 'Изменение статуса',
        message: [`Вы действительно хотите поменять `, ` статус на [${status.text}]?`],
        okButton: 'Да',
        cancelButton: 'Нет',
      });
      if (!ok) return;
      if (status.func) {
        this[status.func](status.value);
      } else {
        this.changeStatus(status);
      }
      this.fitchData();
    },
    async changeStatus(status) {
      if (!this.url || !this.data.id) return;
      this.loading = true;

      let val = status?.setValue ? status.setValue : status.value;
      let data = { id: this.data.id, [status.field]: val };
      if (status?.trigger?.fields) {
        data = Object.assign({}, data, status.trigger.fields);
      }
      let response = await this.$axios.post(this.url, data);
      this.loading = false;
      status = response.data.status == 'ok';
      if (response.data.data) {
        Object.assign(this.data, response.data.data);
        this.$root.$emit('show-info', {
          text: 'Статус изменен',
        });
      }
    },
    status10(s) {
      this.changeStatus(s);
      this.$router.push({ name: 'objects_view', params: { id: this.id } });
    },
    statusSale(s) {
      this.showStateSaleDialog = true;
    },
  },
};
