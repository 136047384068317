/** @format */

import validators from './validators';

export default {
  mixins: [validators],
  methods: {
    validateAll: async function (data, silent = false, parent = '', noerror = true) {
      for (var key in data) {
        if (typeof data[key] === 'object' && data[key] !== null && this.nestedObject.includes(key)) {
          if (await this.validateAll(data[key], silent, key + '.', noerror)) {
          } else {
            noerror = false;
          }
        } else {
          this.errors[parent + key] = null;
          if (await this.validate(parent + key)) {
            noerror = false;
          }
        }
      }
      if (!noerror && !parent) {
        if (!silent)
          this.$root.$emit('show-info', {
            text: 'Не верно заполнены поля',
            type: 'error',
          });
        console.log('validate Errors', this.errors);
      }
      return noerror;
    },
    validate: async function (key) {
      if (!(this.validators[key] || []).includes('ignoreChange')) {
        this.isChanged = true;
      }
      const error = await this.validator(this.validators[key], key, this.data);
      return (this.errors[key] = error);
    },
    validateTask: async function (key) {
      const error = await this.validator(this.validatorsTask[key], key, this.dataTask);
      return (this.errorsTask[key] = error);
    },

    async validateData(data, model, showFieldError = false) {
      let validators = {};
      for (let el of model) {
        if (el.validator) {
          let name = el.name;
          validators[name] = el.validator;
        }
      }
      let errors = {};
      const validate = async (key) => {
        const error = await this.validator(validators[key], key, data);
        console.log('validate', key, data?.[key]);
        return (errors[key] = error);
      };
      const valAll = async (data, parent = '', noerror = true) => {
        for (var key in validators) {
          if (typeof data[key] === 'object' && data[key] !== null && this.nestedObject.includes(key)) {
            if (await valAll(data[key], key + '.', noerror)) {
            } else {
              noerror = false;
            }
          } else {
            errors[parent + key] = null;
            if (await validate(parent + key)) {
              noerror = false;
            }
          }
          if (showFieldError && errors[parent + key]) {
            this.errors[key] = errors[parent + key];
            this.$root.$emit('show-info', {
              text: `${model.find((m) => m.name == key).title} : ${errors[parent + key]}`,
              type: 'error',
            });
          }
        }
        if (!noerror) {
          if (false)
            this.$root.$emit('show-info', {
              text: 'Не верно заполнены поля',
              type: 'error',
            });
          // console.log('validate Errors', this.errors);
        }

        return noerror;
      };
      if (await valAll(data)) {
        //console.log('save', this.data);
        return true;
      }
      return false;
    },
  },
};
