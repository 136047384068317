/** @format
 *       "name": "test credit",
      "address": null,
      "phone": null,
      "whatsapp": null,
      "id": 1,
      "user_id": null,
      "organization_id": 3,
      "status": 1
 */

const credit = {
  title: 'Кредитные организации',
  routeName: 'credit-org',
  accessName: 'creditOrg',
  api: '/mko/credit_org',

  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 450 },
    { name: 'phone', title: 'Телефон', type: 'string', sortable: true, width: 120 },
    { name: 'organization_id', title: 'Организация', type: 'select', sortable: true, width: 120 },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_active', sortable: true, width: 120 },
  ],
  form1: [
    { name: 'name', title: 'Наименование', type: 'string', validator: ['req'], autofocus: true },
    { name: 'address_fact', title: 'Адрес фактический', type: 'address' },
    {
      name: 'address_yur',
      title: 'Адрес юридический',
      type: 'address',
      validator: ['req'],
      config: { clone: { field: 'address_fact', title: 'Соовпадает с фактическим' } },
    },
    { name: 'name_director', title: 'Руководитель', type: 'fioData', validator: ['req'] },
    { name: 'name_manager', title: 'Контактное лицо', type: 'fioData', validator: ['req'] },
    { name: 'phone', title: 'Телефон', type: 'phone', validator: ['req', 'tel'], mask: '996*********' },
    { name: 'whatsapp', title: 'whatsapp', type: 'phone', validator: ['req', 'tel'], mask: '996*********' },
    { name: 'organization_id', title: 'Организация', type: 'selectapi', api: '/users/organization/select', validator: [] },
    { name: 'user_id', title: 'Пользователь', type: 'selectapi', api: '/users/users/select', validator: [] },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_active', default: 0, validator: ['req'] },
    { name: 'data' },
  ],
  form: [
    { name: 'name', title: 'Наименование', type: 'string', validator: ['req'], autofocus: true },
    { name: 'address_fact', title: 'Адрес фактический', type: 'address' },
    {
      name: 'address_yur',
      title: 'Адрес юридический',
      type: 'address',
      validator: ['req'],
      config: { clone: { field: 'address_fact', title: 'Соовпадает с фактическим' } },
    },
    { name: 'name_director', title: 'Руководитель', type: 'fioData', validator: ['req'] },
    { name: 'name_manager', title: 'Ответственное лицо', type: 'fioData', validator: ['req'] },
    { name: 'phone', title: 'Телефон', type: 'phone', validator: ['req', 'tel'], mask: '996*********' },
    { name: 'whatsapp', title: 'whatsapp', type: 'phone', validator: ['req', 'tel'], mask: '996*********' },
    { name: 'category', title: 'Категория', type: 'string' },
    { name: 'type_of_ownership', title: 'Форма собственности', type: 'select', options: 'typeOwnership', validator: ['req'] },
    { name: 'type', title: 'Тип', type: 'select', options: 'typeCreditOrg', validator: ['req'] },
    { name: 'data.inn', title: 'ИНН', type: 'string', validator: ['req'] },
    { name: 'data.okpo', title: 'ОКПО', type: 'string', validator: ['req'] },
    { name: 'organization_id', title: 'Организация', type: 'selectapi', api: '/users/organization/select', validator: [] },
    { name: 'user_id', title: 'Пользователь', type: 'selectapi', api: '/users/users/select', validator: [] },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_active', default: 0, validator: ['req'] },
    { name: 'have_branch', title: 'Есть филиалы', type: 'switcher', default: 0, validator: ['req'] },
    { name: 'branch_id' },
    { name: 'data' },
  ],
  viewForm1: {
    fields: 'name,address_fact,name_director,name_manager,phone',
  },
  viewForm2: {
    fields: 'type,type_of_ownership,data.inn,data.okpo',
  },
  config: {
    default: {
      title: 'Кредитные организация',
      fields: `name,address_fact,address_yur,phone,whatsapp,name_director,name_manager,have_branch,type,
        data.inn,data.okpo,
        organization_id,status,user_id,type_of_ownership`,
      fieldsRO: '',
      fieldsForm: `name,type#9,have_branch#3,name_director,name_manager,address_fact,address_yur,type_of_ownership,
        data.inn#6,data.okpo#6,
        phone#6,whatsapp#6,user_id#6,status#6`,
    },
  },
};
export default credit;
