/** @format */

export default {
  methods: {
    getOptions(model) {
      if (model && model.options) {
        if (Array.isArray(model.options)) return model.options;
        let res = [];
        if (model.options.includes('config.')) {
          return this.getFromConfig(model.options);
        }
        model.options.split(',').forEach((o) => {
          let r = this.$root.config.meta.enum[o] || this.$root[o] || this.$store.state.config.data.config.dictionary?.[o] || [];
          res = [...res, ...r];
        });
        return res;
      }
      if (model && model.field) {
        if (this.values[model.field]) return JSON.parse(this.values[model.field]);
      }

      if (model && model.dir) {
        let name_dir = model.dir;
        let filter_dir = model?.dirFilter || null;
        let dir = this.getFromDir(name_dir, filter_dir);
        return dir;
      }
      if (model && model.dirVar) {
        const key = model.dirVar.key;
        let dir = [];
        if (this.values) {
          const v = model?.json ? this.values?.data?.[key] : this.values[key];
          const dirConf =
            model.dirVar.dirName.find((el) => {
              return el.value == v;
            }) || {};
          let name_dir = dirConf?.name;
          let filter_dir = dirConf?.dirFilter || '';
          if (name_dir) {
            dir = this.getFromDir(name_dir, filter_dir);
          }
        }
        return dir;
      }
    },
    getFromConfig(p) {
      let res = [];
      let tmp;
      const path = p.split('.');
      for (let i = 0; i < path.length; i++) {
        const el = path[i];
        if (i === 0) {
          tmp = JSON.parse(JSON.stringify(this.$store.getters['config/get'] || {}));
        }
        if (el !== 'config') tmp = JSON.parse(JSON.stringify(tmp?.[el] || {}));
        if (el == 'docTable') tmp['AccountingDirContract'] = { name: 'Договора' };
      }
      for (const key in tmp) {
        if (Object.hasOwnProperty.call(tmp, key)) {
          const el = tmp[key];
          const row = { value: key, text: el.name };
          res.push(row);
        }
      }

      return res;
    },
    getFromDir(name, filter = '', text = '') {
      const dir = JSON.parse(JSON.stringify(this.$root.dirs?.[name] || []));
      if (filter) {
        return dir.filter((el) => {
          let res = false;
          if (el)
            for (const key in filter) {
              //console.log('filter by ', key, filter[key], el?.[key]);
              if (key == 'formula') {
                let func;
                if (typeof filter.formula == 'function') func = filter.formula;
                if (typeof filter.formula == 'string') func = eval(filter.formula);
                res = func(el);
              } else if (Array.isArray(filter[key])) {
                if (filter[key].includes(el?.[key])) res = true;
              } else if (filter[key] === '%null') {
                res = el?.[key] === null;
              } else if (filter[key] === '%notNull') {
                res = el?.[key] !== null;
              } else {
                if (el?.[key] == filter[key]) {
                  res = true;
                } else {
                  res = false;
                }
              }
              if (res == false) return false;
            }
          return res;
        });
      } else {
        return dir;
      }
    },
    getFilterOptions(model) {
      if (model && model.search) {
        if (Array.isArray(model.search)) return model.search;
      }
      if (model && model.options) {
        if (Array.isArray(model.options)) return model.options;
        let res = this.$root.config.meta.enum[model.options] || this.$root[model.options] || this.$store.state.config.data.config.dictionary?.[model.options] || [];
        if (model.optionsFilter) {
          let f = model.optionsFilter;

          if (typeof f === 'string') {
            f = f.split(',');
          }
          if (f) return res.filter((r) => f.includes(r.value.toString()));
        }
        return res;
      }
      if (model && model.dir) {
        let name_dir = model.dir;
        let filter_dir = model?.dirFilter || '';
        let text = model?.preText || '';
        let dir = this.getFromDir(name_dir, filter_dir, text);
        return dir;
      }
      if (model && model.dirVar) {
        const key = model.dirVar.key;
        let dir = [];
        for (const dirName of model.dirVar.dirName) {
          let name_dir = dirName.name;
          let filter_dir = dirName?.dirFilter || '';
          let text = dirName?.preText || '';
          let d = this.getFromDir(name_dir, filter_dir, text);
          d.forEach((el) => {
            if (text) {
              el.text = text + el.text;
            }
            if (dirName?.field) {
              el.value = el.value + '##' + dirName.field;
              el.field = dirName.field;
            }
          });

          dir = [...dir, ...d];
        }
        return dir;
      }
    },
    getElementByValue(value, model) {
      let options = this.getOptions(model);
      if (options) {
        return options.find((x) => x && x.value == value) ? options.find((x) => x && x.value == value) : {};
      }
    },
    getTextByValue(value, model) {
      if (model?.aliace) {
        value = this.values?.[model.aliace] || value;
      }
      let text = this.getElementByValue(value, model);
      return text?.text ? text.text : value;
      return text?.text ? text.text : this.$t('none');
    },
    filterItems(d) {
      if (this.model.optionsFilter) {
        let filter = this.model.optionsFilter;
        d.forEach((el) => {
          let res = false;
          for (const key in filter) {
            let f = filter[key];
            if (typeof f == 'string' && f.includes('function(')) {
              try {
                f = eval(f);
              } catch (error) {}
            }
            if (Array.isArray(f)) {
              if (f.includes(el?.[key])) res = true;
            } else if (typeof f === 'function') {
              res = f(el[key], el, this.values);
            } else if (typeof f === 'string' && f.includes('bit#')) {
              let bit = f.split('#')[1];
              res = el[key] & (1 << (bit - 1));
            } else if (el?.[key] == f) res = true;
          }
          el.disabled = !res;
        });
      }

      return d;
    },
  },
};
