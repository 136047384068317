<template>
  <div>
    <v-dialog v-if="type == 1" v-model="showDocument" persistent scrollable @keydown.esc="showDocument = false">
      <v-btn style="position: absolute; right: 20px; top: 20px; z-index: 100" fab dark small color="blue-grey" title="Закрыть окно" @click="showDocument = false">
        <v-icon dark> mdi-close </v-icon>
      </v-btn>
      <v-card style="position: absolute; top: 10px; left: 10px; right: 10px; bottom: 10px; width: auto; margin: auto" height="auto"> </v-card>
    </v-dialog>
    <portal to="v-main"> </portal>
  </div>
</template>

<script>
import { getAccess } from "@/components/mixings";

export default {
  mixins: [getAccess],
  components: {},
  props: {},
  data() {
    return {
      idItemShow: 0,
      docName: "",
      showDocument: false,
      type: null,
    };
  },
  computed: {},
  created() {},
  watch: {
    showDocument(v) {
      if (!v) {
        this.idItemShow = 0;
        this.docName = "";
        this.type = null;
        this.$emit("onClose");
      }
    },
  },
  methods: {
    async openDocument(name, id, row) {
      console.log("openDocument", { name, id, row });
      if (name == "AccountingDocGettingLoan") {
        this.idItemShow = id;
        this.docName = "LoanGet";
        this.type = 1;
        this.showDocument = true;

        //       this.$refs.loanGet.openDialog(name, id); //передаю первым параметром модель, что бы там само определито тип документа
        return;
      } else if (name == "AccountingDocOrderWithdrawalModel") {
        return this.$refs.cashDialog.openDialog(name, id); //передаю первым параметром модель, что бы там само определито тип документа
        return;
      } else if (name == "AccountingDocOrderReceiptModel") {
        this.$refs.cashDialog.openDialog(name, id);
        //this.$refs.cashDialog.openDialog(row.operation_id, id);
        return;
      } else if (name == "AccountingDocOrderGoodModel") {
        this.idItemShow = id;
        this.docName = "OrderGoods";
        this.type = 1;
        this.showDocument = true;
        return;
      } else if (name == "AccountingDocOrderServiceModel") {
        this.idItemShow = id;
        this.docName = "OrderService";
        this.type = 1;
        this.showDocument = true;
        return;
      } else if (name == "AccountingDocActWork") {
        this.type = 2;
        this.idItemShow = id;
        this.showDocument = true;
        this.docName = "ActWork";
        console.log(name, id, row);
        return;
      } else if (name == "Tasks") {
        this.type = 1;
        this.idItemShow = id;
        this.showDocument = true;
        this.docName = "Task";
        console.log(name, id, row);
        return;
      } else if (name == "Buying") {
        this.type = 1;
        this.idItemShow = id;
        this.showDocument = true;
        this.docName = "Buying";
        console.log(name, id, row);
        return;
      } else {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Открытие этого документа в стадии разработки ",
        });
      }
    },
    itemShowClose() {},
  },
};
</script>
