/** @format */
const readonly = true;
const hidden = true;
const dirs = {
  list: [{ value: 0, text: 'Роли', api: 'config', dir: 'roles', name: 'roles', type: 'data', readonly, role: [1000] }],
  roles: {
    list: [
      { name: 'id', title: 'id', type: 'string', sortable: true, width: 300 },
      { name: 'value', title: 'value', type: 'string', sortable: true, width: 300 },
      { name: 'text', title: 'Наименование', type: 'string', sortable: true, width: 300 },
      { name: 'origin', title: 'Системное', type: 'string', sortable: true, width: 300 },
    ],
  },
};
export default dirs;
