<template>
  <span v-html="text"></span>
</template>

<script>
export default {
  props: {
    value: [Array, Number, String],
  },
  computed: {
    roles() {
      return this.$root.dirs.roles;
    },
    users() {
      return this.$root.dirs["user"];
    },
    text() {
      if (this.value) {
        let v;
        if (!Array.isArray(this.value)) v = [this.value];
        else v = [...this.value];
        let res = [];
        v.forEach((el) => {
          if (typeof el == "object") res.push(el.user_name);
          else {
            let user = this.users.find((e) => {
              return e.value == el;
            });
            let u =
              user.text +
              " (" +
              this.roles.find((r) => {
                return r.value == user?.role;
              })?.text +
              ")";
            res.push(u);
          }
        });
        return res.join(";");
      }
    },
  },
};
</script>
