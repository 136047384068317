<template>
  <v-list nav dense>
    <template v-for="(el, i) in value">
      <template v-if="el.auth <= role || isVisible(el)">
        <v-list-group v-if="el.sublist" :key="i" :value="false" :prepend-icon="el.icon">
          <template v-slot:activator>
            <v-list-item-title style="font-size: 1rem; font-weight: 300">
              {{ $t(getTitle(el)) }}
            </v-list-item-title>
          </template>

          <list-main-menu-item v-if="e.auth <= role || isVisible(e)" v-for="(e, i) in el.sublist" :key="i" :value="e" :sublist="true" />
        </v-list-group>
        <list-main-menu-add-menu v-else-if="el.menu" :el="el" :key="i" />
        <list-main-menu-item v-else :key="i" :value="el" />
      </template>
    </template>
  </v-list>
</template>

<script>
import { getAccess } from "@/components/mixings";
export default {
  mixins: [getAccess],

  props: {
    value: Array,
    role: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {},

  methods: {
    isVisible(el) {
      let res = this.getAccess("menu." + (el?.route?.name || el?.accessName || ""));
      return res;
    },
    getTitle(el) {
      let name = el?.route?.name || el?.accessName || "";
      let res = el.name;
      try {
        tmp = JSON.parse(JSON.stringify(this.$store.getters["config/get"].config.access?.menu?.[name] || {}));
        if (tmp?.title?.[this.$root.profile.role]) res = tmp?.title?.[this.$root.profile.role];
      } catch (error) {}
      return res;
    },
  },
};
</script>

<style scoped>
.v-application .primary--text {
  color: #fff !important;
  caret-color: #fff !important;
}
</style>
