/** @format */

const organizations = {
  title: 'Организации',
  routeName: 'organizations',
  accessName: 'organizations',
  api: '/users/organization',

  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 450 },
    { name: 'type', title: 'Тип', type: 'select', sortable: true, width: 200 },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_active', sortable: true, width: 120 },
  ],
  form: [
    { name: 'name', title: 'Наименование', type: 'string', validator: ['req'], autofocus: true },
    { name: 'type', title: 'Тип', type: 'select' },
    { name: 'images', title: 'Фото', type: 'string', validator: ['req'] },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_active', default: 0, validator: ['req'] },
    { name: 'data' },
  ],

  config: {
    default: {
      title: 'Организация',
      fields: 'name,type,imsges,status',
      fieldsRO: '',
      fieldsForm: 'name,type#6,status#6,images',
    },
  },
};
export default organizations;
