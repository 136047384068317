/** @format */

const status = [
  { value: 0, text: 'Новый' },
  { value: 1, text: 'Активный' },
  { value: 10, text: 'Не активный' },
];
const deliveryMan = {
  ///users/organization
  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'name', title: 'Имя', type: 'string', sortable: true, width: 200 },
    { name: 'login', title: 'Логин', type: 'string', sortable: true, width: 150 },
    { name: 'status', title: 'Статус', type: 'select', options: status, sortable: true, width: 200 },
    { name: 'role', title: 'Роль', type: 'select', dir: 'roles', sortable: true, width: 200 },
    { name: 'organization_name', title: 'Организация', type: 'string', sortable: true, width: 200 },
    { name: 'sip_number', title: 'SIP номер', type: 'string', sortable: true, width: 100 },
  ],
  form: [
    { name: 'name', title: 'Имя', type: 'string', validator: ['req'] },
    { name: 'login', title: 'Логин', type: 'string', validator: ['req', 'login'] },
    { name: 'password', title: 'Пароль', type: 'password', validator: ['pass'] },
    { name: 'status', title: 'Статус', type: 'select', options: status, validator: ['req'] },
    { name: 'role', title: 'Роль', type: 'select', dir: 'roles', validator: ['req'] },
    { name: 'phone', title: 'Телефон', type: 'phone', validator: ['req'] },
    { name: 'organization_id', title: 'Организация', type: 'selectapi', api: '/users/organization/select' },
  ],
  config: {
    fields: 'name,login,password,status,role,organization_id',
    fieldsRO: '',
    fieldsForm: 'name,login,password,status,role,organization_id',
  },
};
export default deliveryMan;
