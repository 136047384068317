<template>
  <v-menu v-model="showMenu" absolute offset-y style="max-width: 600px">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">{{ value }}</span>
    </template>

    <v-list>
      <v-list-item dense>
        <v-list-item-title @click="onClickLink"
          >Перейти по ссылке</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: [String, Number],
  },
  data: () => ({
    showMenu: false,
  }),
  methods: {
    onClickLink(e) {
      if (this.value) window.open(this.value);
    },
  },
};
</script>