<template>
  <div class="layout wrap row px-1 pb-0 mt-1">
    <v-text-field
      outlined
      v-model="nameF"
      label="Фамилия"
      :error="nameF.length == 0"
      :error-messages="error"
      :disabled="disabled"
      :hide-details="config.hideDetails || false"
      :dense="config.dense || false"
      :class="model.class || config.class"
    />
    <v-text-field
      outlined
      v-model="nameI"
      label="Имя"
      :error="nameI.length != 0 && nameF.length == 0"
      :error-messages="nameI.length != 0 && nameF.length == 0 ? 'Имя не будет сохранено' : ''"
      :disabled="disabled || !nameF"
      :hide-details="config.hideDetails || false"
      :dense="config.dense || false"
      :class="model.class || config.class"
    />
    <v-text-field
      outlined
      v-model="nameO"
      label="Отчество"
      :error="nameO.length != 0 && nameI.length == 0"
      :error-messages="nameO.length != 0 && nameI.length == 0 ? 'Отчество не будет сохранено' : ''"
      :disabled="disabled || !nameI"
      :hide-details="config.hideDetails || false"
      :dense="config.dense || false"
      :class="model.class || config.class"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    value: [String],
    values: { type: Object, default: () => {} },
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      name: ["", "", ""],
    };
  },
  watch: {
    fullName(v) {
      this.name = v ? v.split(" ") || ["", "", ""] : ["", "", ""];
    },
  },
  created() {},
  mounted() {},
  destroyed() {},
  updated() {},
  computed: {
    fullName: {
      get() {
        return this.value?.trim();
      },
      set(v) {
        //   this.$emit("cascade", { field: "data.name", value: { f: this.nameF, i: this.nameI, o: this.nameO } });
        this.$emit("input", v);
      },
    },
    nameF: {
      get() {
        let name;
        try {
          name = this.fullName.split(" ");
        } catch (error) {
          name = [];
        }
        return name[0] || "";
      },
      set(v) {
        this.fio(0, v);
      },
    },
    nameI: {
      get() {
        let name;
        try {
          name = this.fullName.split(" ");
        } catch (error) {
          name = [];
        }
        return name[1] || "";
      },
      set(v) {
        this.fio(1, v);
      },
    },
    nameO: {
      get() {
        let name;
        try {
          name = this.fullName.split(" ");
        } catch (error) {
          name = [];
        }
        return name[2] || "";
      },
      set(v) {
        this.fio(2, v);
      },
    },
  },
  methods: {
    fio(n, v) {
      this.name[n] = v.trim().replace(" ", "");
      let name = "";

      this.name.forEach((el) => {
        //  name = el.trim() ? name + " " + el.trim() : name;
        name = name + " " + el.trim();
      });
      this.fullName = name.trim();
    },
  },
};
</script>
