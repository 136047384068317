<template>
  <div class="py-1 mb-4">
    <v-card :class="{ 'error--text': showError }">
      <v-card-title class="py-2">
        {{ model.title }}
        <v-spacer></v-spacer>
        <div v-if="!readonly && model.photo !== false" class="text-right pa-1">
          <v-btn class="ma-1" @click="addImage()" color="primary">+ Фото</v-btn>
        </div>
        <div v-if="!readonly && model.video !== false" class="text-right pa-1">
          <v-btn class="ma-1" @click="addVideo()" color="primary">+ Видео</v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <div>
          <v-row style="max-height: 300px; overflow: auto">
            <v-card
              v-for="(el, key) in items"
              :key="key"
              height="150px"
              width="150px"
              class="ma-2"
              @dragover="onDragOver(el, key, $event)"
              @dragend="finishDrag(el, key, $event)"
              @dragstart="startDrag(el, key, $event)"
              @dragleave="onDragLeave(el, key, $event)"
              :class="{ over: el === over.item && el !== dragFrom, [over.dir]: el === over.item && el !== dragFrom }"
            >
              <a :href="$root.config.fileUrl + el.file" target="_blank">
                <v-img :src="$root.config.imageUrl + el.file + '?width=150&height=150'" />
                <v-btn
                  v-if="!readonly"
                  class="mx-0"
                  fab
                  dark
                  x-small
                  color="error"
                  style="position: absolute; right: 8px; top: 8px"
                  title="Удалить"
                  @click.prevent="remove(key)"
                >
                  <v-icon dark> mdi-close </v-icon>
                </v-btn>
              </a>
            </v-card>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <div class="v-text-field__details" v-if="showError">
      <div class="v-messages theme--light" role="alert">
        <div class="v-messages__wrapper error--text">{{ err }}</div>
      </div>
    </div>
    <v-dialog v-model="dialogFile" hide-overlay persistent max-width="600">
      <v-card class="">
        <v-card-title>Загрузка изображений</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-8">
          <v-file-input accept="image/*" multiple outlined chips label="Выберите файл изображений" type="file" v-model="uploadsFiles"></v-file-input>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="uploadsFiles ? false : true" :loading="fileLoading" color="primary" @click="upload()">
            Загрузить <template v-slot:loader> <v-progress-linear v-model="progressLoading" color="green" height="35" rounded></v-progress-linear> </template>
          </v-btn>
          <v-btn @click="dialogFile = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogVideoFile" hide-overlay persistent max-width="600">
      <v-card class="">
        <v-card-title>Загрузка видео</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-8">
          <v-file-input accept="video/*" multiple outlined chips label="Выберите файл изображений" type="file" v-model="uploadsFiles"></v-file-input>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="uploadsFiles ? false : true" :loading="fileLoading" color="primary" @click="upload()">
            Загрузить
            <template v-slot:loader>
              <v-progress-linear v-model="progressLoading" color="green" height="35" rounded></v-progress-linear>
            </template>
          </v-btn>
          <v-btn @click="dialogVideoFile = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: String | Array,
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      uploadsFiles: null,
      dialogVideoFile: false,
      dialogFile: false,
      fileLoading: false,
      showFileAdminDialog: false,
      showError: false,
      taskFiles: [],
      addFiles: [],
      delFiles: [],
      editFile_id: 0,
      err: null,
      over: {},
      startLoc: 0,
      progressLoading: 0,
      dragging: false,
      dragFrom: {},
    };
  },
  watch: {
    error(e) {
      this.err = e;
      if (e) {
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
          this.err = "";
        }, 2000);
      }
    },
  },
  computed: {
    isTask() {
      return this.items.find((t) => t.task);
    },
    group_id() {
      return 1;
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    fileAdmin() {
      return this.$root.profile.role === 1000;
    },
    category() {
      return this.model?.category || null;
    },
    items: {
      get() {
        let d = [...(this.values.filesData || []), ...(this.values.filesTask || [])];
        let f = this.category;
        try {
          d = d.filter((el) => {
            if (this.model?.newOnly && el?.id) return false;
            return f == null || el.category_id == f;
          });
          d.forEach((el) => {
            el.type = "old";
          });
        } catch (error) {
          d = [];
        }
        return d;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    getSize(s) {
      return (s / (1024 * 1024)).toFixed(2);
    },
    configFile(file) {
      if (!file.upload_id) return;
      this.editFile_id = file.upload_id;
      this.showFileAdminDialog = true;
    },
    remove(key) {
      let e = this.items;
      //e.splice(key, 1);
      e[key].type = "del";
      this.items = e;
    },
    addImage() {
      this.uploadsFiles = null;
      this.dialogFile = true;
      this.fileLoading = false;
    },
    add() {
      this.uploadsFiles = null;
      this.dialogFile = true;
      this.fileLoading = false;
    },
    async upload() {
      this.fileLoading = true;
      let promises = [];
      for (let file of this.uploadsFiles) {
        if (file.size / (1024 * 1024) > (this.model?.bigSize || 0) && this.model.big) promises.push(this.uploadBigFile(file));
        else promises.push(this.uploadFile(file));
      }
      await Promise.all(promises);
      this.fileLoading = false;
      this.dialogFile = false;
    },
    uploadBigFile(file) {
      return new Promise((resolve, reject) => {
        let el = this.items;
        if (!Array.isArray(el)) el = [];
        let f = {
          file: null,
          file_name: file.name,
          category_id: this.category,
          upload_id: el.reduce((id, e) => {
            return id + (e.task ? e.upload_id : 0);
          }, -1),
          group_id: this.group_id,
          type: "task",
          task: true,
          fileData: file,
          document: { parent_name: this.model.table_name, parent_id: this.values.id, category_id: this.category },
        };
        if (!this.model.newOnly) {
          console.log("eeeeeeeeeeee");
          this.$root.$emit("show-info", {
            text: "Error: " + "Файл не может быть  добавлен",
            type: "error",
          });
        } else {
          el.splice(0, 0, f);
          this.addFiles.push(f);
          this.items = el;
        } //this.$root.$emit("upload-task", { file, api: this.model.api, data: { id: this.values.id } });
        resolve();
      });
    },

    uploadFile(file) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("name", file.name);
        this.progressLoading = 0;
        let count = this.dialogVideoFile ? this.uploadsFiles.length : 1;
        let api = this.dialogVideoFile ? "/file/upload/video" : "/image/upload";
        // file.uploadStatus = 0;
        this.$axios
          .post("/file/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              this.progressLoading = parseInt((progressEvent.loaded / progressEvent.total) * 100);
            }, // onUploadProgress: (progressEvent) => {
            //   file.uploadStatus = parseInt(
            //     (progressEvent.loaded / progressEvent.total) * 100
            //   );
            // },
          })
          .then((response) => {
            let data = response.data.data.file;
            let el = this.items;
            if (!Array.isArray(el)) el = [];
            let f = {
              file: data,
              file_name: file.name,
              category_id: this.model?.category || null,
              upload_id: response.data.data.id,
              group_id: this.group_id,
              type: "add",
            };
            this.addFiles.push(f);
            el.splice(0, 0, f);
            this.items = el;
            resolve();
          })
          .catch(function (error) {
            console.log(error);
            // this.$root.$emit("show-info", {
            //   text: "Error: " + error,
            // });
            reject(error);
          });
      });
    },
    startDrag(item, i, e) {
      e.dataTransfer.setData("text", item);
      e.dataTransfer.setData("from", JSON.stringify(this.model));
      e.dataTransfer.effectAllowed = "move";
      // this.startLoc = e.clientY;
      this.dragging = true;
      this.dragFrom = this.model.name;
    },
    finishDrag(item, pos) {
      if (this.readonly) return;
      let el = this.images;
      el.splice(pos, 1);
      el.splice(this.over.pos, 0, item);
      this.images = el;
      setTimeout(() => {
        this.over = {};
      }, 50);
    },

    onDragOver(item, pos, e) {
      if (this.over.pos == pos) return;
      if (this.dragFrom != this.model.name) return;
      if (this.readonly) return;
      e.preventDefault();
      let dir;
      // dir = this.startLoc < e.clientY ? "down" : "up";
      this.over = { item, pos, dir };
      //      setTimeout(() => {        this.over = { item, pos, dir };      }, 50);
    },
    onDragLeave(item, pos, e) {
      if (this.over.pos == pos) this.over = {};
    },
  },
};
</script>
