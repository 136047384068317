<template>
  <div class="pa-2">
    <v-card style="border-radius: 8px" :class="{ 'pb-0 ': !$vuetify.breakpoint.smAndDown }">
      <span v-if="header" class="text_no_wrap" :class="{ 'pb-1 pl-1': $vuetify.breakpoint.smAndDown }">
        <slot name="header" />
      </span>
      <v-card-title v-if="title" :class="{ 'pa-2 ': $vuetify.breakpoint.smAndDown, 'pb-2 pt-3': !$vuetify.breakpoint.smAndDown }">
        <span class="text_no_wrap" :class="{ 'pb-1 pl-1': $vuetify.breakpoint.smAndDown }">
          <slot name="title" />
          <v-spacer />
        </span>
        <v-spacer />
        <div class="text_no_wrap fullscreen_card__actions" :class="{ 'mt-1': !$vuetify.breakpoint.smAndDown }">
          <slot name="actions"></slot>
        </div>
      </v-card-title>
      <v-card-text :style="{ height: contentHeight + 'px' }" style="overflow-y: auto; overflow-x: hidden" :class="{ 'py-2 px-2': $vuetify.breakpoint.smAndDown }">
        <slot></slot>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { staticFullHeight } from "./mixings";

export default {
  mixins: [staticFullHeight],
  props: { title: { type: Boolean, default: true }, header: { type: Boolean, default: false } },
};
</script>

<style lang="scss">
.text_no_wrap {
  overflow: auto;
  white-space: nowrap;
}
.fullscreen_card__actions {
  .v-btn {
    margin-left: 5px;
  }
}
</style>
