<template>
  <span>{{ value ? v : null }}</span>
</template>

<script>
export default {
  props: {
    value: [String, Object],
    model: Object,
  },
  computed: {
    v() {
      const f = "city,address";
      const d = this.value;
      let res = "";
      f.split(",").forEach((n) => {
        res += (res ? " " : "") + (d?.[n] || "");
      });
      return res;
    },
  },
};
</script>
