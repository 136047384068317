<template>
  <div class="py-1 pb-3">
    <v-card>
      <v-card-title class="py-2">
        {{ model.title }}
        <v-spacer></v-spacer>
        <div class="text-right pa-1">
          <v-btn v-if="!readonly" class="ma-1" @click="add()" color="primary"> Добавить </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <div>
          <v-row>
            <v-simple-table style="width: 100%">
              <tbody>
                <tr v-for="(el, key) in items" :key="key">
                  <td class="text-left">
                    <a :href="$root.config.fileUrl + el.file" target="_blank">
                      <i v-if="el.task" style="color: red" class="far fa-clock"></i> {{ el.file_name || el.file }}
                      <small v-if="el.task"> ({{ getSize(el.fileData.size) }} Mb)</small>
                    </a>
                  </td>
                  <td width="10px">
                    <div class="d-flex">
                      <v-btn v-if="fileAdmin" class="mx-2" fab small title="Настройки" @click.prevent="configFile(el)">
                        <v-icon> mdi-cog </v-icon>
                      </v-btn>
                      <v-btn v-if="!readonly" class="mx-2" fab dark small color="error" title="Удалить" @click.prevent="remove(key)">
                        <v-icon dark> mdi-close </v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <div v-if="isTask" style="color: red">
              <i style="color: red" class="far fa-clock"></i> Файлы будут добавлены в очередь загрузки и появятся в документе после окончания загрузки
            </div>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialogFile" hide-overlay persistent max-width="600">
      <v-card>
        <v-card-title>Загрузка файлов</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-8">
          <v-file-input multiple outlined chips label="Выберите файл" type="file" v-model="uploadsFiles"></v-file-input>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="uploadsFiles ? false : true" :loading="fileLoading" color="primary" @click="upload()">Загрузить </v-btn>
          <v-btn @click="dialogFile = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <s-fileConfigDialog v-if="showFileAdminDialog" v-model="showFileAdminDialog" :id="editFile_id" />
  </div>
</template>

<script>
export default {
  props: {
    value: String | Array,
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      uploadsFiles: null,
      dialogFile: false,
      fileLoading: false,
      showFileAdminDialog: false,
      taskFiles: [],
      addFiles: [], 
      delFiles: [],
      editFile_id: 0,
    };
  },
  computed: {
    isTask() {
      return this.items.find((t) => t.task);
    },
    group_id() {
      return 1;
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    fileAdmin() {
      return this.$root.profile.role === 1000;
    },
    category() {
      return this.model?.category || null;
    },
    items: {
      get() {
        let d = [...(this.values.filesData || []), ...(this.values.filesTask || [])];
        let f = this.category;
        try {
          d = d.filter((el) => {
            if (this.model?.newOnly && el?.id) return false;
            return f == null || el.category_id == f;
          });
          d.forEach((el) => {
            el.type = "old";
          });
        } catch (error) {
          d = [];
        }
        return d;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    getSize(s) {
      return (s / (1024 * 1024)).toFixed(2);
    },
    configFile(file) {
      if (!file.upload_id) return;
      this.editFile_id = file.upload_id;
      this.showFileAdminDialog = true;
    },
    remove(key) {
      let e = this.items;
      //e.splice(key, 1);
      e[key].type = "del";
      this.items = e;
    },
    add() {
      this.uploadsFiles = null;
      this.dialogFile = true;
      this.fileLoading = false;
    },
    async upload() {
      this.fileLoading = true;
      let promises = [];
      for (let file of this.uploadsFiles) {
        if (file.size / (1024 * 1024) > (this.model?.bigSize || 0) && this.model.big) promises.push(this.uploadBigFile(file));
        else promises.push(this.uploadFile(file));
      }
      await Promise.all(promises);
      this.fileLoading = false;
      this.dialogFile = false;
    },
    uploadBigFile(file) {
      return new Promise((resolve, reject) => {
        let el = this.items;
        if (!Array.isArray(el)) el = [];
        let f = {
          file: null,
          file_name: file.name,
          category_id: this.category,
          upload_id: el.reduce((id, e) => {
            return id + (e.task ? e.upload_id : 0);
          }, -1),
          group_id: this.group_id,
          type: "task",
          task: true,
          fileData: file,
          document: { parent_name: this.model.table_name, parent_id: this.values.id, category_id: this.category },
        };
        if (!this.model.newOnly) {
          console.log("eeeeeeeeeeee");
          this.$root.$emit("show-info", {
            text: "Error: " + "Файл не может быть  добавлен",
            type: "error",
          });
        } else {
          el.splice(0, 0, f);
          this.addFiles.push(f);
          this.items = el;
        } //this.$root.$emit("upload-task", { file, api: this.model.api, data: { id: this.values.id } });
        resolve();
      });
    },

    uploadFile(file) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("name", file.name);
        // file.uploadStatus = 0;
        this.$axios
          .post("/file/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            // onUploadProgress: (progressEvent) => {
            //   file.uploadStatus = parseInt(
            //     (progressEvent.loaded / progressEvent.total) * 100
            //   );
            // },
          })
          .then((response) => {
            let data = response.data.data.file;
            let el = this.items;
            if (!Array.isArray(el)) el = [];
            let f = {
              file: data,
              file_name: file.name,
              category_id: this.model?.category || null,
              upload_id: response.data.data.id,
              group_id: this.group_id,
              type: "add",
            };
            this.addFiles.push(f);
            el.splice(0, 0, f);
            this.items = el;
            resolve();
          })
          .catch(function (error) {
            console.log(error);
            // this.$root.$emit("show-info", {
            //   text: "Error: " + error,
            // });
            reject(error);
          });
      });
    },
  },
};
</script>
