<template>
  <div>
    <v-btn v-if="id && api" color="primary" :disabled="disabled" @click.stop="onClick()"> {{ title }} </v-btn>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Введите код из СМС </v-card-title>
        <v-card-text>
          Введите код который вы получили в СМС на свой телефон.
          <div>
            <v-btn :disabled="sending" color="red darken-1" text @click="onGetCode()"> {{ interval.send > 0 ? interval.send + " " : "" }} Выслать код </v-btn>
          </div>
          <v-otp-input v-model="code" length="4" type="number"></v-otp-input>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="dialog = false"> Отмена </v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="code?.length !== 4 || confirming" color="green darken-1" text @click="onSendCode()">
            {{ interval.confirm > 0 ? interval.confirm + " " : "" }} Отправить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    api: String,
    id: [Number, String],
    title: { type: String, default: "SMS" },
    disabled: { type: Boolean, default: false },
    beforeSend: Function,
  },
  data() {
    return {
      code: null,
      dialog: false,
      finish: false,
      sending: false,
      confirming: false,
      interval: { send: 0, confirm: 0 },
      timer: { send: null, confirm: null },
    };
  },
  created() {
    console.log("btn send create", { id: this.id, api: this.api, disabled: this.disabled });
  },
  methods: {
    async onClick() {
      if (this.beforeSend) {
        if (await this.beforeSend()) this.dialog = true;
      } else this.dialog = true;
    },
    delay(name, n) {
      this.interval[name] = n;
      this.timer[name] = setInterval(() => {
        if (this.interval?.[name]) {
          this.interval[name]--;
        } else clearTimeout(this.timer[name]);
      }, 1000);
    },
    async onGetCode() {
      this.sending = true;
      try {
        const d = await this.$axios.get(this.api + "/getconfirm/" + this.id);
        console.log(d.data);
        if (d.data && d.data.data?.result) {
          this.$root.$emit("show-info", {
            time: 10000,
            text: `СМС с кодом отправлена на номер  ${d.data.data.data.phone}`,
          });
        } else
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка при отправки СМС",
          });
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка " + error?.data?.message,
        });
      }
      this.delay("send", 60);
      setTimeout(() => {
        this.sending = false;
      }, 60000);
    },
    async onSendCode() {
      this.confirming = true;
      try {
        const d = await this.$axios.post(this.api + "/sendconfirm/" + this.id, { code: this.code });
        console.log(d.data);
        if (d.data && d.data.data?.result) {
          this.$root.$emit("show-info", {
            text: "Код принят",
          });
          this.$emit("success");
          this.dialog = false;
        } else
          this.$root.$emit("show-info", {
            type: "error",
            text: "Не верный код",
          });
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка" + error?.message,
        });
      }
      this.code = null;
      this.delay("confirm", 5);

      setTimeout(() => {
        this.confirming = false;
      }, 12000);
    },
  },
};
</script>
